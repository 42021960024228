import { addOrganizationProspectList } from 'api/organization.service';
import React, { useState } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  FloatingLabel,
  Form,
  Modal,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

const AddListModal = ({
  organization,
  onHide,
  setOrganization,
  show,
  userLogged,
}) => {
  const [error, setError] = useState<any>(null);
  const [description, setDescription] = useState<any>(null);
  const [name, setName] = useState<any>(null);

  const handleAddList = () => {
    if (!name) setError('Please, provide a name');
    //else if (!description) setError('Please, provide a description');
    else {
      setError(null);
      addOrganizationProspectList(
        { description, email: userLogged.email, name },
        { _id: organization?._id },
      ).then(response => {
        setDescription(null);
        setName(null);
        setOrganization(response);
        toast.success('List created successfully');
        onHide();
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create New List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FloatingLabel label="Name">
          <Form.Control
            className="mb-3"
            value={name ?? ''}
            onChange={e => setName(e.target.value)}
          />
        </FloatingLabel>
        <FloatingLabel label="Description">
          <Form.Control
            value={description ?? ''}
            onChange={e => setDescription(e.target.value)}
          />
        </FloatingLabel>
        {!!error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddList}>
            Add
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default AddListModal;
