import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';

export const EventBasedTable = ({
  data,
  maxRows = undefined,
  onColumnFilter,
  onColumnHeader,
  onRowColumn,
  onSelect,
  selected,
  setSelected,
}: {
  data: any;
  maxRows?: number;
  onColumnFilter?: any;
  onColumnHeader?: any;
  onRowColumn?: any;
  onSelect?: any;
  selected?: any;
  setSelected?: any;
}) => {
  const columns = Object.keys(data?.[0] ?? {}).sort();
  const filteredColumns = columns.filter(column =>
    !!onColumnFilter ? onColumnFilter(column) : true,
  );
  const columnHeaders = filteredColumns.map(column =>
    !!onColumnHeader ? onColumnHeader(column) : column,
  );
  const [allSelected, setAllSelected] = useState(false);

  const handlToggle = item => {
    const index = (selected ?? []).indexOf(item);

    if (index >= 0) {
      selected.splice(index, 1);
      setSelected([...selected]);
    } else setSelected([...(selected ?? []), item]);
  };

  const handleToggleAll = () => {
    if (allSelected) setSelected(null);
    else setSelected([...data]);
  };

  useEffect(() => {
    setAllSelected(selected?.length === data.length);
  }, [data.length, selected?.length]);

  useEffect(() => {
    if (!!selected) onSelect(selected);
  }, [onSelect, selected]);

  return (
    <Table striped bordered responsive size="sm">
      <thead>
        <tr>
          <th></th>
          {!!onSelect && (
            <th>
              <Form.Check checked={allSelected} onChange={handleToggleAll} />
            </th>
          )}
          {columnHeaders.map(column => (
            <th className="text-nowrap">{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(maxRows ? data.slice(0, maxRows) : data).map((row, index) => (
          <tr>
            <td>{index + 1}</td>
            {!!onSelect && (
              <td>
                <Form.Check
                  checked={(selected ?? []).includes(row)}
                  onChange={() => handlToggle(row)}
                />
              </td>
            )}
            {filteredColumns.map(column => (
              <td style={{ whiteSpace: 'nowrap' }}>
                {onRowColumn ? onRowColumn(row, column) : row[column]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
