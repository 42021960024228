import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import constants from 'app/config/constants';
import { createPricePaymentIntent } from 'api/stripe.service';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import dayjs from 'dayjs';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CheckoutForm from './CheckoutForm';
import { Button, Card, Carousel, Image, Table } from 'react-bootstrap';
import LoadingOverlay from '../LoadingOverlay';
import {
  ArrowLeftCircleFill,
  CheckCircle,
  CheckCircleFill,
} from 'react-bootstrap-icons';
import { orgHasAccess } from 'utils/orgHasAccess';
import { orgCannotAccess } from 'utils/orgCannotAccess';
import './styles.css';
import CartScreenFullPredict from '../../assets/cartScreenFullPredict.png';
import CartScreenMap from '../../assets/cartScreenMap.png';
import CartScreenMapPopup from '../../assets/cartScreenMapPopup.png';
import CartScreenMapPopupOnly from '../../assets/cartScreenMapPopupOnly.png';
import CartScreenQuickPredict from '../../assets/cartScreenQuickPredict.png';
import QNA from '../QNA';
import PromotionalCode from '../PromotionalCode';

const Catalog = ({
  organization,
  onPurchase = null,
}: {
  organization: any;
  onPurchase?: any;
}) => {
  const userLogged = useSelector(getAuthUser);
  const [activeQuestionKey, setActiveQuestionKey] = useState<any>('0');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<any>(null);
  const [activeItem, setActiveItem] = useState<any>(0);
  const [quantity, setQuantity] = useState<any>(50);

  const promise = loadStripe(constants.STRIPE_PUBLIC_KEY()!);
  const itemList = [
    {
      bullets: [
        <>
          <a href="#browseHoverSee" onClick={() => handleOpenQuestion()}>
            Browse
          </a>{' '}
          the internet using your Sidekick
        </>,
        <>
          <a href="#browseHoverSee" onClick={() => handleOpenQuestion()}>
            Hover
          </a>{' '}
          over any highlighted name on your screen
        </>,
        <>
          <a href="#browseHoverSee" onClick={() => handleOpenQuestion()}>
            See
          </a>{' '}
          light personal profiles
        </>,
        'See predicted state, predicted wealth, and predicted political views',
        'Sidekick available for Chrome/Edge',
      ],
      creditsHeader: 'Credits included: 0',
      creditsSub: '(no ability to license or contact prospects)',
      cost: 0,
      images: [CartScreenQuickPredict],
      name: 'SIDEKICK BASIC',
      oneTimePurchase: true,
      subHeader: 'Browse, Hover & See Quick Profiles',
      suffix: '/month',
    },
    {
      bullets: [
        <>
          <a href="#browseHoverSee" onClick={() => handleOpenQuestion()}>
            Browse
          </a>
          ,{' '}
          <a href="#browseHoverSee" onClick={() => handleOpenQuestion()}>
            Hover
          </a>{' '}
          and{' '}
          <a href="#browseHoverSee" onClick={() => handleOpenQuestion()}>
            See
          </a>{' '}
          personal profiles
        </>,
        'Plus contact information on prospects',
        'Unlimited monthly roll-over of credits',
        'Full Profiles for a name appearing on your screen',
        'See predicted state, predicted wealth, and predicted political views',
        'Plus predicted asset range (detailed)',
        'Plus predicted household income (detailed)',
        'Plus predicted interests and passions (detailed)',
        'Predicted donation areas (detailed)',
        'Sidekick available for Chrome/Edge',
      ],
      creditsHeader: 'Credits: 25 credits/month included',
      creditsSub: 'Use credits to obtain contact information on prospects',
      highlight: true,
      images: [CartScreenFullPredict],
      name: 'SIDEKICK PLUS',
      oneTimePurchase: true,
      cost: 25,
      priceId: constants.STRIPE_PRICE_BROWSER_SIDEKICK(),
      subHeader: 'Add Detailed Profiles and Contact Information',
      suffix: '/month',
    },
    {
      bullets: [
        <>
          <a href="#browseHoverSee" onClick={() => handleOpenQuestion()}>
            Browse
          </a>
          ,{' '}
          <a href="#browseHoverSee" onClick={() => handleOpenQuestion()}>
            Hover
          </a>{' '}
          and{' '}
          <a href="#browseHoverSee" onClick={() => handleOpenQuestion()}>
            See
          </a>{' '}
          personal profiles and contact information
        </>,
        'Access searchable national database of Full Prediction Profiles on over 200 million U.S. adults',
        'Full PatternIQ dashboard to search, sort, license, and organize PatternIQ predictions for use in your outreach',
        'Unlimited monthly roll-over of credits',
        'See predicted state, predicted wealth, and predicted political views',
        'Plus predicted asset range (detailed)',
        'Plus predicted household income (detailed)',
        'Plus predicted interests and passions (detailed)',
        'Predicted donation areas (detailed)',
        'Sidekick available for Chrome/Edge',
      ],
      creditsHeader: 'Credits: 500 credits/month included',
      creditsSub:
        'Use credits to search & sort prospects and obtain prospect contact information',
      images: [CartScreenMapPopupOnly, CartScreenMapPopup, CartScreenMap],
      name: 'PROFESSIONAL CLIENT FINDER',
      oneTimePurchase: true,
      cost: 500,
      priceId: constants.STRIPE_PRICE_BASIC_ACCESS(),
      subHeader:
        'Search Database of 200+ Million Full Client Profiles and Contact Information',
      suffix: '/month',
    },
    {
      creditsSub:
        '50 credits for use to buy Full Predictions and search the national Professional Prediction Database',
      className: 'other',
      name: 'ADDITIONAL CREDITS',
      oneTimePurchase: false,
      cost: 1 * (quantity ?? 50),
      priceId: constants.STRIPE_PRICE_CREDIT(),
      quantity,
      requiresPriceId: [
        constants.STRIPE_PRICE_BASIC_ACCESS(),
        constants.STRIPE_PRICE_BROWSER_SIDEKICK(),
      ],
      separate: true,
      suffix: '/once',
    },
  ];

  const handlePurchase = purchase => {
    setActiveItem(null);
    onPurchase(purchase);
    window.location.href = '/';
  };

  const handleOpenQuestion = (key = '3') => {
    setActiveQuestionKey(key);
  };

  useEffect(() => {
    if (!!userLogged?.email && !paymentInfo && !isLoading && !!activeItem) {
      setIsLoading(true);

      createPricePaymentIntent({
        item: activeItem,
        organizationId: userLogged?.organizationId,
        customer: '',
        description: `Purchased ..., at ${dayjs().format()}`,
        customerPayload: {
          email: userLogged?.email || '',
          name: `${userLogged?.firstName || ''} ${userLogged?.lastName || ''}`,
          phone: '',
        },
        receipt_email: userLogged?.email,
        quantity,
        metadata: {
          email: userLogged?.email || '',
          organizationId: userLogged?.organizationId || '',
        },
      }).then(response => {
        if (!!response) {
          setPaymentInfo(response);
          setIsLoading(false);
        }
      });
    }
  }, [
    isLoading,
    paymentInfo,
    activeItem,
    userLogged?.email,
    userLogged?.firstName,
    userLogged?.lastName,
    userLogged?.organizationId,
    quantity,
  ]);

  if (!activeItem)
    return (
      <>
        <h1 className="my-3 text-center">
          Choose the right plan for your needs.
        </h1>
        <h5 className="my-3 text-center">3 Plans to Choose From:</h5>

        <Row className="row-eq-height pt-3">
          {itemList.map(item => {
            return (
              <Col md={Math.floor(itemList.length / 12)}>
                {!!item.highlight && (
                  <div className="d-none d-md-block highlight-header rounded-top-4 shadow" />
                )}
              </Col>
            );
          })}
        </Row>

        <Row className="row-eq-height">
          {itemList.map((item, index) => {
            const hasAccess = orgHasAccess(organization, item);
            const disabled = orgCannotAccess(organization, item);
            const isLast = index === itemList.length - 1;

            return (
              <Col
                md={Math.floor(itemList.length / 12)}
                className={`mb-5 ${!!item.separate ? 'border-start' : ''}`}
              >
                <Card
                  className={`${!!item.highlight ? 'highlight' : ''} ${
                    item.className
                  } ${
                    !item.highlight ? 'rounded-4' : 'rounded-bottom-4'
                  } shadow`}
                >
                  <Card.Body>
                    <div className="fs-5">
                      {hasAccess && disabled && (
                        <CheckCircleFill className="text-dark me-1" />
                      )}
                      {item.name}
                    </div>

                    <div className="mb-3 itemSubHeader">{item.subHeader}</div>

                    <div className="mb-3">
                      <span className="fs-3">${item.cost}</span>
                      {item.suffix}
                      {!item.cost && <span> (No credit card required)</span>}
                    </div>

                    {!!item.creditsHeader && (
                      <div className={`fs-5 fw-bold`}>{item.creditsHeader}</div>
                    )}

                    {!!item.creditsHeader && !!item.creditsSub && (
                      <div className="creditsSub">{item.creditsSub}</div>
                    )}

                    {!item.creditsHeader && !!item.creditsSub && (
                      <div className={`fs-5`}>
                        <span className={`fw-bold`}>Credits: </span>
                        {item.creditsSub}
                      </div>
                    )}

                    {!!item.bullets && (
                      <div className="mt-3">
                        <div className="fs-5 fw-bold">Features:</div>
                        <Table responsive borderless>
                          <tbody className="border-0">
                            {item.bullets.map(bullet => (
                              <tr>
                                <td>
                                  <CheckCircle />
                                </td>
                                <td>{bullet}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}

                    {!!item.images && (
                      <Carousel controls={false} indicators={false}>
                        {item.images.map(image => (
                          <Carousel.Item>
                            <Image fluid src={image} />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    )}
                  </Card.Body>

                  <Card.Footer>
                    <Button
                      disabled={disabled}
                      variant="dark"
                      className="w-100"
                      onClick={() => {
                        if (!!item.cost) {
                          setActiveItem(item);
                          setPaymentInfo(null);
                          setQuantity(item.quantity);
                        } else window.location.href = '/';
                      }}
                    >
                      {!!item.cost
                        ? hasAccess && disabled
                          ? 'Already Purchased'
                          : 'Purchase'
                        : 'Try'}
                    </Button>
                  </Card.Footer>
                </Card>

                {isLast && (
                  <Card className="mt-3 rounded-4 shadow">
                    <Card.Body>
                      <Card.Title>Promotional Code</Card.Title>
                      <PromotionalCode user={userLogged} />
                    </Card.Body>
                  </Card>
                )}
              </Col>
            );
          })}
        </Row>

        <Row>
          <Col>
            <h1 className="mb-4 mt-3 text-center">Questions you might have.</h1>
            <QNA defaultActiveKey={activeQuestionKey} />
          </Col>
        </Row>
      </>
    );
  return (
    <>
      <LoadingOverlay show={isLoading} />

      {!isLoading && (
        <Row className="">
          <Col lg={4}></Col>
          <Col lg={4}>
            <Button
              variant="link"
              className="fs-3 text-dark"
              onClick={() => setActiveItem(0)}
            >
              <ArrowLeftCircleFill />
            </Button>
          </Col>
          <Col lg={4}></Col>
        </Row>
      )}

      {!isLoading && paymentInfo?.paymentIntent?.client_secret && (
        <Row className="row-eq-height">
          <Col lg={4}></Col>
          <Col
            lg={4}
            className="overflow-auto"
            style={{ maxHeight: 'calc(100vh - 100px)' }}
          >
            <Elements
              stripe={promise}
              options={{
                clientSecret: paymentInfo.paymentIntent.client_secret,
              }}
            >
              <CheckoutForm
                paymentInfo={paymentInfo}
                item={activeItem}
                onPurchase={handlePurchase}
              />
            </Elements>
          </Col>
          <Col lg={4}></Col>
        </Row>
      )}
    </>
  );
};

export default Catalog;
