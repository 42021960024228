import constants from 'app/config/constants';
import axiosInstance from 'utils/api';

export const matchCachedProspects = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/optdb/matchCachedProspects`;

  return await axiosInstance().post(url, body);
};

export const matchCachedProspectsCount = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/optdb/matchCachedProspectsCount`;

  return await axiosInstance().post(url, body);
};
