import React, { useEffect, useState } from 'react';
import {
  Collapsable,
  CollapsableBody,
  CollapsableHeader,
  CollapsableItem,
} from '../Collapse';
import GuideSection from '../MOADBFacetedSearch/GuideSection';

const QNA = ({ defaultActiveKey = '0' }) => {
  const [activeQuestionKey, setActiveQuestionKey] = useState<any>(
    defaultActiveKey ?? '0',
  );

  useEffect(() => {
    setActiveQuestionKey(defaultActiveKey);
  }, [defaultActiveKey]);

  return (
    <GuideSection dontFillHeight>
      {/*<h1 className="my-3 text-center">Questions you might have.</h1>*/}

      <Collapsable className="facet mb-3">
        <CollapsableItem>
          <CollapsableHeader isCollapsed={false}>
            What is PatternIQ?
          </CollapsableHeader>
          <CollapsableBody>
            PatternIQ is a customer intelligence tool that helps Business Users
            find new customers and clients. The PatternIQ Platform contains a
            web browser extension (we call “Sidekick”) and a
            Software-as-a-Service (SaaS) platform. PatternIQ Users can see
            A.I.-modeled insights into prospective customers or clients, search
            for prospects nation-wide, and license the contact information for
            prospects.
          </CollapsableBody>
        </CollapsableItem>

        <CollapsableItem>
          <CollapsableHeader>
            What are Prediction Profiles and how are they created?
          </CollapsableHeader>
          <CollapsableBody>
            Prediction Profiles are profiles on individual U.S. adults derived
            using Artificial Intelligence analyzing open-source data.
            Importantly, Prediction Profiles are just that: predictions. They
            are not verified statements of confirmed fact. Viewing a Prediction
            Profile is most analogous to receiving the A.I.-results of an
            instantaneous, open-source internet search on an individual.
            PatternIQ’s A.I. scours all data available on an individual and then
            mathematically models the most likely attributes about that
            individual that would help a commercial enterprise outreach to that
            person (e.g., that individual’s wealth, ideology, or interests).
            PatternIQ has created Prediction Profiles on over 200 million U.S.
            adults. Prediction Profiles come in two different levels of detail
            depending on a User’s Membership Plan.
          </CollapsableBody>
        </CollapsableItem>

        <CollapsableItem>
          <CollapsableHeader>What is the Sidekick?</CollapsableHeader>
          <CollapsableBody>
            PatternIQ’s Browser Sidekick is a web browser extension (currently
            available on Chrome and Edge) that allows Users to passively see
            Prediction Profiles while browsing the web. It works like this:
            while a User browses the web, the Sidekick identifies text on
            screens that represents human names. Sidekick then automatically
            accesses the Prediction Profile on the person represented by that
            name, and allows the User to hover over the name to see the
            Prediction Profile. Users with paid subscriptions can then see
            additional details and license contact information for that
            individual.
          </CollapsableBody>
        </CollapsableItem>

        <CollapsableItem>
          <CollapsableHeader>
            What is meant by Browse, Hover, and See?
          </CollapsableHeader>
          <CollapsableBody>
            This is how Sidekick works. A User Browses the internet using
            Sidekick, Hovers over any highlighted name on the screen, and Sees
            the Prediction Profile on that person.
          </CollapsableBody>
        </CollapsableItem>

        <CollapsableItem>
          <CollapsableHeader>
            How do PatternIQ Credits work? Do they roll-over?
          </CollapsableHeader>
          <CollapsableBody>
            PatternIQ credits are automatically included in paid membership
            Plans. 1 credit = $1 dollar. Credits are used to license the contact
            information on prospects so Users can outreach to that prospect.
            Credits are also used to search the Prediction Profiles on over 200
            million U.S. adults. Don’t worry, PatternIQ Credits always roll-over
            into the next month if not used.
          </CollapsableBody>
        </CollapsableItem>

        <CollapsableItem>
          <CollapsableHeader>How does billing work?</CollapsableHeader>
          <CollapsableBody>
            After a PatternIQ User chooses the right plan, the User is prompted
            to provide a credit card or other digital means of payment.
            PatternIQ’s billing occurs through a Stripe payment integration.
            User’s are billed monthly on a recurring basis via the payment means
            provided. See PatternIQ’s User Agreement, Purchase Agreement, Terms
            & Conditions for more details.
          </CollapsableBody>
        </CollapsableItem>

        <CollapsableItem>
          <CollapsableHeader>
            How do I cancel my subscription?
          </CollapsableHeader>
          <CollapsableBody>
            PatternIQ customers may cancel at any time. The effective date for
            cancellation of the Plan is the end of the current term (usually
            monthly billing cycle). We ask that you provide us with 14 days
            notice in order to allow us to process the cancellation before the
            next monthly invoice. See PatternIQ’s User Agreement, Purchase
            Agreement, Terms & Conditions for more details.
          </CollapsableBody>
        </CollapsableItem>

        <CollapsableItem>
          <CollapsableHeader>
            What about privacy laws? What are my rights?
          </CollapsableHeader>
          <CollapsableBody>
            PatternIQ takes privacy seriously. We have a 3-part{' '}
            <a
              href="https://www.dredata.ai/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            available here. PART 1 applies to information we collect from Users
            of the PatternIQ Platform. PART 2 sets forth PatternIQ’s policies
            and practices for handling information PatternIQ may possess or
            process about any individual, whether or not they have interacted
            with the Platform. And PART 3 provide general privacy obligations
            that apply to both PART 1 and PART 2. Regulations around data
            privacy change rapidly, so PatternIQ’s{' '}
            <a
              href="https://www.dredata.ai/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            is subject to change. Please review the{' '}
            <a
              href="https://www.dredata.ai/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            periodically.
          </CollapsableBody>
        </CollapsableItem>
      </Collapsable>
    </GuideSection>
  );
};

export default QNA;
