import { sendPasswordlessLogin } from 'api/auth.service';
import ToolTip from 'app/components/ToolTip';
import React, { useState } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  FloatingLabel,
  Form,
  Modal,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

const PasswordlessButton = () => {
  const [email, setEmail] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    if (!(email ?? '').match(/^[^@]+@[^.]+\..+$/)) {
      setError('Please, enter a valid email');
    } else {
      setError(null);
      sendPasswordlessLogin(email).then(response => {
        if (!!response) {
          setShowModal(false);
          toast.success('Passwordless link sent. Please, check your email.');
        }
      });
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Send Access Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            Forgot your password? No worries. Enter your email and click{' '}
            <i>Send Link</i> to have an access link emailed to you. Clicking the
            access link will log you in without a password.
          </div>
          <FloatingLabel label="Email">
            <Form.Control
              value={email ?? ''}
              onChange={e => setEmail(e.target.value)}
            />
          </FloatingLabel>
          {!!error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button variant="light" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              <span className="text-white">Send Link</span>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>

      <ToolTip title="Email a link that allows you to login without a password.">
        <Button
          variant="success"
          className="text-decoration-none text-white w-100"
          onClick={() => setShowModal(true)}
        >
          Send Access Link
        </Button>
      </ToolTip>
    </>
  );
};

export default PasswordlessButton;
