import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';

const SuggestEditModal = ({ onHide, onSend, show, suggestionTarget }) => {
  const [suggestion, setSuggestion] = useState<any>(null);

  const send = () => {
    onSend(suggestion);
    setSuggestion(null);
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Suggest Edit for {suggestionTarget?.first_name}{' '}
            {suggestionTarget?.last_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fs-5 mb-3">
            Your suggested edits will be validated. If confirmed, you will
            receive 10 free credits per edited profile
          </div>

          <Form.Control
            rows={3}
            as="textarea"
            value={suggestion ?? ''}
            onChange={e => setSuggestion(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button variant="light" onClick={onHide}>
              Cancel
            </Button>
            <Button onClick={send} disabled={!suggestion}>
              Send
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuggestEditModal;
