import React from 'react';
import ToolTip from 'app/components/ToolTip';

import { Badge } from 'react-bootstrap';

const getRowColumn = (filteredColumns, row, column) =>
  filteredColumns.indexOf(column.column) >= 0 ? row[column.column] : null;

const IntersetsIcons = ({ prospect, filteredColumns = [] }) => {
  return (
    <>
      {[
        {
          column: 'donates_to_animal_welfare',
          caption: 'Animals',
        },
        {
          column: 'donates_to_arts_and_culture',
          caption: 'Arts & Culture',
        },
        {
          column: 'donates_to_childrens_causes',
          caption: 'Children',
        },
        {
          column: 'donates_to_conservative_causes',
          caption: 'Conservatism',
        },
        {
          column: 'donates_to_healthcare',
          caption: 'Healthcare',
        },
        {
          column: 'donates_to_international_aid_causes',
          caption: 'International Aid',
        },
        {
          column: 'donates_to_liberal_causes',
          caption: 'Liberalism',
        },
        {
          column: 'donates_to_local_community',
          caption: 'Community',
        },
        {
          column: 'donates_to_political_causes',
          caption: 'Politics',
        },
        {
          column: 'donates_to_veterans_causes',
          caption: 'Veterans',
        },
        {
          column: 'donates_to_wildlife_preservation',
          caption: 'Wildlife',
        },
      ]
        .filter(interest =>
          ['1', 'YES'].includes(
            (
              getRowColumn(filteredColumns, prospect, interest) ?? ''
            ).toUpperCase(),
          ),
        )
        .sort()
        .map(interest => (
          <ToolTip
            title={interest.column
              .toLowerCase()
              .replace(/_/g, ' ')
              .replace(/\b([a-z])/g, a => a.toUpperCase())}
          >
            <Badge pill bg="light" text="dark" className="me-1 p-1">
              {interest.caption}
            </Badge>
          </ToolTip>
        ))}
    </>
  );
};

export default IntersetsIcons;
