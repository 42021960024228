import React from 'react';
import './styles.css';
import InfoToolTip from '../InfoToolTip';

export const InsetLabel = ({
  children,
  label,
  info,
}: {
  children?: any;
  label?: string;
  info?: string;
}) => {
  return (
    <>
      {!!label && (
        <span className="insetLabel">
          {label}
          {!!info && (
            <InfoToolTip title={info} style={{ marginLeft: '0.5rem' }} />
          )}
        </span>
      )}
      {children}
    </>
  );
};
