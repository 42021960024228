import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';

export interface EnvJSONSliceState {
  ANALYTICS_CLIENT_ID?: string;
  ANALYTICS_ENV?: string;
  ANALYTICS_MEASUREMENT_ID?: string;
  ANALYTICS_SECRET?: string;
  DPT_LOAD_LIMIT?: number;
  DPT_SAVE_LIMIT?: number;
  GOOGLE_CLIENT_ID?: string;
  GOOGLE_CLIENT_SECRET?: string;
  GOOGLE_REDIRECT_URI?: string;
  BASE_ENDPOINT?: string;
  BASE_ENDPOINT_WS?: string;
  STRIPE_PUBLIC_KEY?: string;
  STRIPE_PRICE_BASIC_ACCESS?: string;
  STRIPE_PRICE_BROWSER_SIDEKICK?: string;
  STRIPE_PRICE_CREDIT?: string;
  PARCEL_CARD_CREDITS?: number;
}

export const initialState: EnvJSONSliceState = {
  ANALYTICS_CLIENT_ID: '',
  ANALYTICS_ENV: '',
  ANALYTICS_MEASUREMENT_ID: '',
  ANALYTICS_SECRET: '',
  DPT_LOAD_LIMIT: 20,
  DPT_SAVE_LIMIT: 20,
  GOOGLE_CLIENT_ID: '',
  GOOGLE_CLIENT_SECRET: '',
  GOOGLE_REDIRECT_URI: '',
  BASE_ENDPOINT: '',
  BASE_ENDPOINT_WS: '',
  STRIPE_PUBLIC_KEY: '',
  STRIPE_PRICE_BASIC_ACCESS: '',
  STRIPE_PRICE_BROWSER_SIDEKICK: '',
  STRIPE_PRICE_CREDIT: '',
  PARCEL_CARD_CREDITS: 0,
};

const envJSONSlice = createSlice({
  name: 'envJSON',
  initialState,
  reducers: {
    loadEnvJSON: (
      state,
      action: PayloadAction<EnvJSONSliceState, string, never>,
    ) => {
      state.ANALYTICS_CLIENT_ID = action.payload.ANALYTICS_CLIENT_ID;
      state.ANALYTICS_ENV = action.payload.ANALYTICS_ENV;
      state.ANALYTICS_MEASUREMENT_ID = action.payload.ANALYTICS_MEASUREMENT_ID;
      state.ANALYTICS_SECRET = action.payload.ANALYTICS_SECRET;
      state.DPT_LOAD_LIMIT = action.payload.DPT_LOAD_LIMIT;
      state.DPT_SAVE_LIMIT = action.payload.DPT_SAVE_LIMIT;
      state.GOOGLE_CLIENT_ID = action.payload.GOOGLE_CLIENT_ID;
      state.GOOGLE_CLIENT_SECRET = action.payload.GOOGLE_CLIENT_SECRET;
      state.GOOGLE_REDIRECT_URI = action.payload.GOOGLE_REDIRECT_URI;
      state.BASE_ENDPOINT = action.payload.BASE_ENDPOINT;
      state.BASE_ENDPOINT_WS = action.payload.BASE_ENDPOINT_WS;
      state.STRIPE_PUBLIC_KEY = action.payload.STRIPE_PUBLIC_KEY;
      state.STRIPE_PRICE_BASIC_ACCESS =
        action.payload.STRIPE_PRICE_BASIC_ACCESS;
      state.STRIPE_PRICE_BROWSER_SIDEKICK =
        action.payload.STRIPE_PRICE_BROWSER_SIDEKICK;
      state.STRIPE_PRICE_CREDIT = action.payload.STRIPE_PRICE_CREDIT;
      state.PARCEL_CARD_CREDITS = action.payload.PARCEL_CARD_CREDITS;
    },
  },
});

export const useEnvJSONSlice = () => {
  useInjectReducer({
    key: envJSONSlice.name,
    reducer: envJSONSlice.reducer,
  });
  return { actions: envJSONSlice.actions };
};

export default envJSONSlice.reducer;
