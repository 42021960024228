import React from 'react';
import TOS from './TOS';
import { updateUser } from 'api/user.service';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuthSliceSlice } from 'store/authentication/authentication';

const AcceptToS = ({ user }) => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();
  const dispatch = useDispatch();
  const {
    actions: { acceptedToS },
  } = useAuthSliceSlice();

  const onAgreement = () => {
    updateUser({ acceptedToS: true }, { _id: user._id }).then(() => {
      setCookie('user', { ...cookies?.user, acceptedToS: true });
      dispatch(acceptedToS());
      navigate('/settings');
    });
  };

  return <TOS onAgreement={onAgreement} />;
};

export default AcceptToS;
