import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  FloatingLabel,
  Form,
  InputGroup,
} from 'react-bootstrap';
import {
  Collapsable,
  CollapsableBody,
  CollapsableHeader,
  CollapsableItem,
} from '../Collapse';
import { CheckSquare, XSquare } from 'react-bootstrap-icons';

const AIGenerationForm = ({
  aiLetterSubject,
  onChange = (data: any, error?: any) => {},
  organization,
}) => {
  const [availableInterests] = useState(
    [
      {
        column: 'donates_to_animal_welfare',
        caption: 'Animals',
      },
      {
        column: 'donates_to_arts_and_culture',
        caption: 'Arts & Culture',
      },
      {
        column: 'donates_to_childrens_causes',
        caption: 'Children',
      },
      {
        column: 'donates_to_conservative_causes',
        caption: 'Conservatism',
      },
      {
        column: 'donates_to_healthcare',
        caption: 'Healthcare',
      },
      {
        column: 'donates_to_international_aid_causes',
        caption: 'International Aid',
      },
      {
        column: 'donates_to_liberal_causes',
        caption: 'Liberalism',
      },
      {
        column: 'donates_to_local_community',
        caption: 'Community',
      },
      {
        column: 'donates_to_political_causes',
        caption: 'Politics',
      },
      {
        column: 'donates_to_veterans_causes',
        caption: 'Veterans',
      },
      {
        column: 'donates_to_wildlife_preservation',
        caption: 'Wildlife',
      },
    ].filter(interest => !!aiLetterSubject?.[interest.column]),
  );

  const [importantFacets, setImportantFacets] = useState<any>(
    organization?.importantFacets ?? null,
  );
  const [importantInterests, setImportantInterests] = useState<any>(
    organization?.importantInterests ?? null,
  );
  const [outcome, setOutcome] = useState<any>(organization?.outcome ?? null);
  const [productOrService, setProductOrService] = useState<any>(
    organization?.productOrService ?? null,
  );
  const [recipient] = useState(
    `${aiLetterSubject?.first_name} ${aiLetterSubject?.last_name}`,
  );
  const [sender, setSender] = useState<any>(organization?.sender ?? {});
  const [signature, setSignature] = useState<any>(
    organization?.signature ?? null,
  );
  const [type, setType] = useState(
    !!aiLetterSubject?.primary_email
      ? organization?.generativeAIType ?? 'email'
      : 'card',
  );

  const facetCategories = [
    'Location',
    'Interests',
    'Politics',
    'Religion',
    'Wealth',
  ];

  const handleImportantFacetClick = facet => {
    const index = (importantFacets ?? []).indexOf(facet);

    if (index >= 0) {
      importantFacets.splice(index, 1);
      setImportantFacets([...importantFacets]);
    } else setImportantFacets([...(importantFacets ?? []), facet]);
  };

  const handleImportantInterestClick = interest => {
    const index = (importantInterests ?? []).indexOf(interest);

    if (index >= 0) {
      importantInterests.splice(index, 1);
      setImportantInterests([...importantInterests]);
    } else setImportantInterests([...(importantInterests ?? []), interest]);
  };

  const validateStep1Fields = useCallback(() => {
    let newError: any = null;
    const isCard = type === 'card';

    if (!productOrService) newError = 'Please, provide a product/service.';
    else if (!outcome) newError = 'Please, provide a desired outcome.';
    else if (isCard) {
      if (!sender?.firstName) newError = "Please, provide sender's first name.";
      else if (!sender?.address1)
        newError = "Please, provide sender's address.";
      else if (!sender?.city) newError = "Please, provide sender's city.";
      else if (!sender?.state) newError = "Please, provide sender's state.";
      else if (!sender?.zip) newError = "Please, provide sender's zip.";
    }
    return newError;
  }, [
    outcome,
    productOrService,
    sender?.address1,
    sender?.city,
    sender?.firstName,
    sender?.state,
    sender?.zip,
    type,
  ]);

  useEffect(() => {
    const error = validateStep1Fields();

    onChange(
      {
        importantFacets,
        importantInterests,
        outcome,
        productOrService,
        sender,
        signature,
        type,
      },
      error,
    );
  }, [
    importantFacets,
    importantInterests,
    onChange,
    outcome,
    productOrService,
    sender,
    signature,
    type,
    validateStep1Fields,
  ]);

  return (
    <>
      <Collapsable>
        <CollapsableItem>
          <CollapsableHeader className="mb-3">
            <CheckSquare style={{ color: 'green' }} /> What type of text would
            you like to generate?
          </CollapsableHeader>
          <CollapsableBody>
            <FloatingLabel label="Type of Text" className="mb-3">
              <Form.Select
                value={type ?? ''}
                onChange={e => setType(e.target.value)}
              >
                {!!aiLetterSubject?.primary_email && (
                  <option value="email">Email</option>
                )}
                <option value="card">Card</option>
              </Form.Select>
            </FloatingLabel>
          </CollapsableBody>
        </CollapsableItem>

        <CollapsableItem>
          <CollapsableHeader className="mb-3">
            {!!productOrService ? (
              <CheckSquare style={{ color: 'green' }} />
            ) : (
              <XSquare style={{ color: 'red' }} />
            )}{' '}
            What product or service do you provide?
          </CollapsableHeader>
          <CollapsableBody>
            <FloatingLabel label="Product/Service" className="mb-3">
              <Form.Control
                value={productOrService ?? ''}
                onChange={e => setProductOrService(e.target.value)}
              />
            </FloatingLabel>
          </CollapsableBody>
        </CollapsableItem>

        <CollapsableItem>
          <CollapsableHeader className="mb-3">
            {!!outcome ? (
              <CheckSquare style={{ color: 'green' }} />
            ) : (
              <XSquare style={{ color: 'red' }} />
            )}{' '}
            What action would you like the {recipient} to make? For instance, do
            you want {recipient} to sign up for a service or puchase a product?
          </CollapsableHeader>
          <CollapsableBody>
            <FloatingLabel label="Outcome" className="mb-3">
              <Form.Control
                value={outcome ?? ''}
                onChange={e => setOutcome(e.target.value)}
              />
            </FloatingLabel>
          </CollapsableBody>
        </CollapsableItem>

        {type === 'card' && (
          <>
            <CollapsableItem>
              <CollapsableHeader className="mb-3">
                {!!sender.firstName &&
                !!sender.address1 &&
                !!sender.city &&
                !!sender.state &&
                !!sender.zip ? (
                  <CheckSquare style={{ color: 'green' }} />
                ) : (
                  <XSquare style={{ color: 'red' }} />
                )}{' '}
                Please, provide sender information.
              </CollapsableHeader>
              <CollapsableBody>
                <InputGroup>
                  <FloatingLabel label="Name / First Name" className="mb-3">
                    <Form.Control
                      value={sender.firstName ?? ''}
                      onChange={e =>
                        setSender({
                          ...(sender ?? {}),
                          firstName: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Last Name" className="mb-3">
                    <Form.Control
                      value={sender.lastName ?? ''}
                      onChange={e =>
                        setSender({
                          ...(sender ?? {}),
                          lastName: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </InputGroup>

                <InputGroup>
                  <FloatingLabel label="Address 1" className="mb-3">
                    <Form.Control
                      value={sender.address1 ?? ''}
                      onChange={e =>
                        setSender({
                          ...(sender ?? {}),
                          address1: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Address 2" className="mb-3">
                    <Form.Control
                      value={sender.address2 ?? ''}
                      onChange={e =>
                        setSender({
                          ...(sender ?? {}),
                          address2: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </InputGroup>

                <InputGroup>
                  <FloatingLabel label="City" className="mb-3">
                    <Form.Control
                      value={sender.city ?? ''}
                      onChange={e =>
                        setSender({
                          ...(sender ?? {}),
                          city: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                  <FloatingLabel label="State" className="mb-3">
                    <Form.Control
                      value={sender.state ?? ''}
                      onChange={e =>
                        setSender({
                          ...(sender ?? {}),
                          state: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Zip" className="mb-3">
                    <Form.Control
                      type="number"
                      value={sender.zip ?? ''}
                      onChange={e =>
                        setSender({
                          ...(sender ?? {}),
                          zip: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </InputGroup>
              </CollapsableBody>
            </CollapsableItem>

            <CollapsableItem>
              <CollapsableHeader className="mb-3">
                <CheckSquare style={{ color: 'green' }} /> How should it be
                signed?
              </CollapsableHeader>
              <CollapsableBody>
                <Form.Control
                  className="mb-3"
                  as="textarea"
                  rows={3}
                  value={signature ?? ''}
                  onChange={e => setSignature(e.target.value)}
                />
              </CollapsableBody>
            </CollapsableItem>
          </>
        )}

        <CollapsableItem>
          <CollapsableHeader className="mb-3">
            <CheckSquare style={{ color: 'green' }} /> What characteristics of{' '}
            {recipient} do you want the text to focus on?
          </CollapsableHeader>
          <CollapsableBody>
            <ButtonGroup className="w-100">
              {facetCategories.map(facetCategory => (
                <Button
                  className={
                    (importantFacets ?? []).includes(
                      facetCategory.toLowerCase(),
                    )
                      ? ''
                      : 'border'
                  }
                  variant={
                    (importantFacets ?? []).includes(
                      facetCategory.toLowerCase(),
                    )
                      ? 'primary'
                      : 'light'
                  }
                  onClick={() =>
                    handleImportantFacetClick(facetCategory.toLowerCase())
                  }
                >
                  {facetCategory}
                </Button>
              ))}
            </ButtonGroup>
          </CollapsableBody>
        </CollapsableItem>

        {(importantFacets ?? []).includes('interests') &&
          !!availableInterests?.[0] && (
            <CollapsableItem>
              <CollapsableHeader className="mb-3">
                <CheckSquare style={{ color: 'green' }} /> What interests of{' '}
                {recipient} do you want the text to focus on?
              </CollapsableHeader>
              <CollapsableBody>
                <ButtonGroup className="w-100">
                  {availableInterests.map(interest => (
                    <Button
                      className={
                        (importantInterests ?? []).includes(interest.column)
                          ? ''
                          : 'border'
                      }
                      variant={
                        (importantInterests ?? []).includes(interest.column)
                          ? 'primary'
                          : 'light'
                      }
                      onClick={() =>
                        handleImportantInterestClick(interest.column)
                      }
                    >
                      {interest.caption}
                    </Button>
                  ))}
                </ButtonGroup>
              </CollapsableBody>
            </CollapsableItem>
          )}
      </Collapsable>
    </>
  );
};

export default AIGenerationForm;
