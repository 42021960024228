import constants from 'app/config/constants';
import { orgPaymentHistory } from './orgPaymentHistory';
import { orgCreditsUsed } from './orgCreditsUsed';

export const orgCredits = organization => {
  return (
    orgPaymentHistory(organization)
      .filter(payment =>
        [
          constants.STRIPE_PRICE_BASIC_ACCESS(),
          constants.STRIPE_PRICE_BROWSER_SIDEKICK(),
          constants.STRIPE_PRICE_CREDIT(),
        ].includes(payment.price),
      )
      .map(payment => payment.total)
      .reduce((p, c) => p + c, 0) /
      100 -
    orgCreditsUsed(organization)
  );
};
