import constants from 'app/config/constants';
import axiosInstance from 'utils/api';
import { websocketRequest } from 'utils/wsAPI';

export const generateAILetter = async body => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/chatgpt/generateAILetter`;

  return await axiosInstance().post(url, body);
};

export const matchExtractedDataToMOADB = (body, callback) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/chatgpt/matchExtractedDataToMOADB/?method=post&Session-Token=${encodeURIComponent(
    sessionStorage.getItem('Session-Token') ?? '',
  )}`;

  websocketRequest(url, body, callback);
};
