import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

export const Toggle = ({
  className,
  disabled = false,
  labelOff,
  labelOn,
  onChange,
  singleButton = false,
  size,
  value,
}: {
  className?: string;
  disabled?: boolean;
  labelOff?: any;
  labelOn?: any;
  onChange?: any;
  singleButton?: boolean;
  size?: string;
  value?: any;
}) => {
  if (singleButton)
    return (
      <Button
        disabled={disabled}
        className="w-50"
        variant={value ? 'primary' : 'outline-primary'}
        onClick={() => onChange(!!value ? false : true)}
      >
        {!!value ? labelOn : labelOff}
      </Button>
    );
  else
    return (
      <ButtonGroup className={`w-100 ${className}`} size={size as any}>
        <Button
          disabled={disabled}
          className="w-50"
          variant={value ? 'primary' : 'outline-dark'}
          onClick={() => onChange(true)}
        >
          {labelOn}
        </Button>
        <Button
          disabled={disabled}
          className="w-50"
          variant={!value ? 'primary' : 'outline-dark'}
          onClick={() => onChange(false)}
        >
          {labelOff}
        </Button>
      </ButtonGroup>
    );
};
