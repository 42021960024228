import React, { useCallback, useEffect, useState } from 'react';
import {
  CollapsableBody,
  CollapsableHeader,
  CollapsableItem,
} from '../../Collapse';
import { EventBasedTable } from '../../EventBasedTable';
import { Button, Form } from 'react-bootstrap';
import ToolTip from 'app/components/ToolTip';
import SelectListModal from '../SelectListModal';
import { updateOrganization } from 'api/organization.service';
import { useConfirmation } from 'custom-hooks/useConfirmation';
import moment from 'moment';
import { Pencil } from 'react-bootstrap-icons';
import SequenceModal from 'app/components/SequenceModal';

const ListManager = ({
  allowRemove,
  defaultList,
  includePolitical,
  listId,
  organization,
  prospects,
  revealedColumns,
  setOrganization,
  setRevealedColumns,
  setShowEditModal,
  showContactInfo,
  userLogged,
}) => {
  const [applicableProspects, setApplicableProspects] = useState<any>(null);
  const [collapsed, setCollapsed] = useState(true);
  const { confirm, ConfirmationModal } = useConfirmation();
  const [key, setKey] = useState<any>(null);
  const [list, setList] = useState<any>(defaultList);
  const [selected, setSelected] = useState<any>(null);
  const [showSelectModal, setShowSelectModal] = useState<any>(null);
  const [showSequenceModal, setShowSequenceModal] = useState<any>(null);

  const handleCopy = async lists => {
    lists.forEach(list => {
      list.updatedAt = new Date().toISOString();
      list.prospectId = [
        ...(list.prospectId ?? []),
        ...selected.map(prospect => prospect.id),
      ].filter((item, index, array) => index === array.indexOf(item));
    });

    await updateOrganization(
      { prospect_list: organization.prospect_list },
      { Organization_ID: userLogged?.organizationId },
    );

    setOrganization({ ...organization });
    setShowSelectModal(false);
  };

  const handleRemove = async () => {
    if (!!list) {
      const removedIDs = selected.map(prospect => prospect.id);
      const updatedProspectList = [
        ...organization.prospect_list.filter(
          prospectList => prospectList.id !== listId,
        ),
        {
          ...list,
          prospectId: list.prospectId.filter(id => !removedIDs.includes(id)),
          updatedAt: new Date().toISOString(),
        },
      ];

      await updateOrganization(
        { prospect_list: updatedProspectList },
        { Organization_ID: userLogged?.organizationId },
      );

      setOrganization({ ...organization, prospect_list: updatedProspectList });
      setShowSelectModal(false);
      setSelected(null);
    }
  };

  const handleSelect = newSelected => {
    setSelected(newSelected);
  };

  const onColumnFilter = useCallback(
    (column, forDownload = false) => {
      return (
        ![
          'email_deliverability_code_list',
          'email_list',
          'email_match_type_list',
          'estimated_rental_value',
          'home_value_estimated_at',
          'household_income_net',
          'moadb_voter_id_legacy',
          ...(forDownload
            ? ['address_latitude', 'address_longitude', 'birth_date', 'dt_id']
            : []),
        ].includes(column) &&
        (includePolitical ||
          ![
            'general_election_turnout',
            'jurisdiction_name',
            'modeled_party',
            'modeled_spectrum_brackets',
            'modeled_spectrum_score',
            'ideology',
            'state_house_district',
            'state_senate_district',
            'us_house_district',
            'voter_status',
          ].includes(column))
      );
    },
    [includePolitical],
  );

  const onColumnHeaderChanged = useCallback(
    column => {
      const index = revealedColumns.indexOf(column);

      if (index >= 0) revealedColumns.splice(index, 1);
      else revealedColumns.push(column);

      setRevealedColumns([...revealedColumns]);
    },
    [revealedColumns, setRevealedColumns],
  );

  const onColumnHeader = useCallback(
    (column, includeChecks = true) => {
      let columnHeader = column
        .replace(/[^a-z0-9_]/gi, '')
        .replace(/_/g, ' ')
        .replace(/\s+/g, ' ')
        .trim();

      if (column === 'cell_phone_alesco') columnHeader = 'cell phone a';
      else if (column === 'cell_phone_hub224') columnHeader = 'cell phone b';
      else if (column === 'count_1') columnHeader = 'count';
      else if (column === 'landline_phone_alesco')
        columnHeader = 'landline_phone_a';
      else if (column === 'landline_phone__hub224')
        columnHeader = 'landline phone b';
      else if (column === 'moadb_voter_id') columnHeader = 'id';
      else if (column === 'other_phone_alesco') columnHeader = 'other phone a';

      return !includeChecks ||
        (!showContactInfo &&
          [
            'address_1',
            'address_2',
            'address_latitude',
            'address_longitude',
            'cell_phone_alesco',
            'cell_phone_hub224',
            'email_list',
            'landline_phone_alesco',
            'landline_phone__hub224',
            'other_phone_alesco',
          ].includes(column)) ? (
        columnHeader
      ) : (
        <Form.Check
          checked={revealedColumns.includes(column)}
          label={columnHeader}
          onChange={() => onColumnHeaderChanged(column)}
        />
      );
    },
    [onColumnHeaderChanged, revealedColumns, showContactInfo],
  );

  const onRowColumn = (row, column) => {
    if (revealedColumns.includes(column)) return row[column];
    else return '*'.repeat((row?.[column] ?? '').length);
  };

  useEffect(() => {
    if (listId) {
      const newList = {
        ...((organization.prospect_list ?? []).filter(
          prospectList => prospectList.id === listId,
        )?.[0] ?? {}),
      };

      const newKey = JSON.stringify(newList);

      if (key !== newKey) {
        setKey(newKey);
        setList(newList);
      }
    }
  }, [key, list, listId, organization]);

  useEffect(() => {
    if (!!list)
      setApplicableProspects(
        prospects.filter(prospect =>
          (list.prospectId ?? []).includes(prospect.id),
        ),
      );
  }, [list, prospects]);

  if (!applicableProspects) return null;
  return (
    <>
      {ConfirmationModal}

      <SelectListModal
        okLabel={'Copy'}
        onHide={() => setShowSelectModal(false)}
        onOK={handleCopy}
        organization={organization}
        show={showSelectModal}
      />

      {showSequenceModal && (
        <SequenceModal
          onHide={() => setShowSequenceModal(false)}
          organization={organization}
          show={showSequenceModal}
        />
      )}

      <CollapsableItem>
        <CollapsableHeader
          onToggle={newCollapsed => setCollapsed(newCollapsed)}
        >
          <div key={`${key}-header-content}`}>
            <div className="align-items-center justify-content-center">
              {list.name}

              {!!setShowEditModal && (
                <ToolTip title="Edit list" placement="top">
                  <Button
                    variant="link"
                    size="sm"
                    className="align-top ms-1 p-0 text-dark text-decoration-none"
                    onClick={e => {
                      e.stopPropagation();
                      setShowEditModal(list);
                    }}
                  >
                    <Pencil className="text-secondary" />
                  </Button>
                </ToolTip>
              )}

              {!collapsed && !!selected && !!allowRemove && (
                <ToolTip
                  title="Removed selected from this list"
                  placement="top"
                >
                  <Button
                    variant="dark"
                    size="sm"
                    className="ms-2 py-0"
                    onClick={e => {
                      e.stopPropagation();
                      confirm(
                        'Remove Prospects',
                        'Remove prosects from this list?',
                        () => handleRemove(),
                      );
                    }}
                  >
                    Remove
                  </Button>
                </ToolTip>
              )}

              {!collapsed && !!selected && (
                <ToolTip title="Copy selected to another list" placement="top">
                  <Button
                    variant="dark"
                    size="sm"
                    className="ms-2 py-0"
                    onClick={e => {
                      e.stopPropagation();
                      setShowSelectModal(true);
                    }}
                  >
                    Copy
                  </Button>
                </ToolTip>
              )}
            </div>
            <small className="text-secondary">
              {list.description} ({applicableProspects?.length.toLocaleString()}{' '}
              prospects
              <span className="text-secondary">
                , created {moment(list.createdAt).format('MMM Do YY')}
              </span>
              {!!list.updatedAt && (
                <span>
                  , updated {moment(list.updatedAt).format('MMM Do YY')}
                </span>
              )}
              )
            </small>
          </div>
        </CollapsableHeader>
        <CollapsableBody>
          <EventBasedTable
            data={applicableProspects}
            maxRows={500}
            onColumnFilter={onColumnFilter}
            onColumnHeader={onColumnHeader}
            onRowColumn={onRowColumn}
            onSelect={handleSelect}
            selected={selected}
            setSelected={setSelected}
          />
        </CollapsableBody>
      </CollapsableItem>
    </>
  );
};

export default ListManager;
