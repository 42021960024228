import {
  matchCachedProspects,
  matchCachedProspectsCount,
} from 'api/optdb.service';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap';
import { Hint } from 'react-bootstrap-typeahead';
import Papa from 'papaparse';
import readXlsxFile from 'read-excel-file';
import LoadingOverlay from '../LoadingOverlay';
import GeneralModal from '../GeneralModal/GeneralModal';
import { splitArray } from '../MOADBFacetedSearch/utils';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import generateCSV from 'utils/generateCSV';
import GeneralConfirmationModal from '../GeneralConfirmationModal/GeneralConfirmationModal';
import { getOneOrganization } from 'api/organization.service';
import { orgCredits } from 'utils/orgCredits';
import { v4 as uuidv4 } from 'uuid';
import fieldMapOptions from './fieldMapOptions';
import FieldMappingDropdown from './FieldMappingDropdown';
import { levenshteinEditDistance } from 'levenshtein-edit-distance';
import './styles.css';
import { Toggle } from '../Toggle';

export const MOADBMatch = () => {
  const userLogged = useSelector(getAuthUser);

  const [credits, setCredits] = useState<any>(0);
  const [enableMatchButton, setEnableMatchButton] = useState(false);
  const [exampleData, setExampleData] = useState<any>(null);
  const [fieldMap, setFieldMap] = useState<any>(null);
  const fileRef = useRef<any>();
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState<any>(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [hasHeaderRow, setHasHeaderRow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [matchCount, setMatchCount] = useState<any>(null);
  const [message, setMessage] = useState<any>(null);
  const [organization, setOrganization] = useState<any>(null);

  const autoFill = useCallback(() => {
    const newFieldMap = { ...(fieldMap ?? {}) };
    const optionTaken = Object.values(fieldMap ?? {});
    const allFieldsKeys = Object.keys(fieldMapOptions).sort();

    const stringCompare = (a, b) => {
      const length = Math.min(a.length, b.length);
      return levenshteinEditDistance(
        a.substring(0, length),
        b.substring(0, length),
        true,
      );
    };

    const leastDistance = optionKey =>
      Math.min(
        ...Object.keys(fileData?.[0] ?? {}).map(fieldKey =>
          Math.min(
            ...fieldMapOptions[optionKey].alias
              .filter(a => !optionTaken.includes(a))
              .map(alias => stringCompare(fieldKey, alias))
              .reduce((p, c) => [...p, c], []),
          ),
        ),
      );

    Object.keys(fileData?.[0] ?? {})
      .sort()
      .forEach(fieldKey => {
        if (!(fieldKey in newFieldMap)) {
          const fieldMapOptionDistance = allFieldsKeys
            .filter(a => !optionTaken.includes(a))
            .map(optionKey => {
              const minDistance = leastDistance(optionKey);
              const distance = Math.min(
                ...fieldMapOptions[optionKey].alias
                  .map(alias => stringCompare(fieldKey, alias))
                  .reduce((p, c) => [...p, c], []),
              );

              return {
                field: optionKey,
                distance: distance === minDistance ? distance : 1000,
              };
            });

          fieldMapOptionDistance.sort((a, b) => a.distance - b.distance);

          if (
            !!fieldMapOptionDistance?.[0] &&
            fieldMapOptionDistance[0].distance >= 0
          ) {
            newFieldMap[fieldKey] = fieldMapOptionDistance[0].field;
            optionTaken.push(fieldMapOptionDistance[0].field);
          }
        }
      });

    return newFieldMap;
  }, [fieldMap, fileData]);

  const onFileSelect = e => {
    setFile(e?.target?.files[0]);
    setFileExtension(
      e?.target?.files?.[0]?.name?.split('.')?.pop()?.toLowerCase(),
    );
  };

  const onGenerateCSV = async () => {
    try {
      setLoading(true);

      const transactionId = uuidv4();
      const chunks = splitArray(fileData ?? [], 10000) as Object[][];

      const matches: any = [];

      for (let c = 0; c < chunks.length; c++) {
        const chunk = chunks[c];

        const response = await matchCachedProspects({
          email: userLogged?.email,
          fieldMap,
          prospects: chunk,
          transactionId,
        });

        matches.push(...response.data.results);
      }

      setFile(null);
      setFileData(null);
      setFileExtension(null);
      setHasHeaderRow(true);
      fileRef!.current!.value = null;

      generateCSV({ data: matches });
      setCredits(credits - matches.length);
      setMatchCount(null);
    } catch (error) {
      setErrorMessage((error as any)?.message ?? 'Unknown');
    } finally {
      setLoading(false);
    }
  };

  const onUpload = useCallback(async () => {
    try {
      setLoading(true);

      const chunks = splitArray(fileData ?? [], 10000) as Object[][];

      let newMatchCount = 0;

      for (let c = 0; c < chunks.length; c++) {
        const chunk = chunks[c];

        const response = await matchCachedProspectsCount({
          email: userLogged?.email,
          fieldMap,
          prospects: chunk,
        });

        newMatchCount += parseInt(response?.data?.tally, 10);
      }

      setMatchCount(newMatchCount);
    } catch (error) {
      setErrorMessage((error as any)?.message ?? 'Unknown');
    } finally {
      setLoading(false);
    }
  }, [fieldMap, fileData, userLogged?.email]);

  const setErrorMessage = error =>
    setMessage({
      isError: true,
      title: 'Matching',
      message: (
        <>
          <div className="mb-3">
            Matching encountered an error. Please contact us with the follwing
            information:
          </div>
          <Form.Control
            as="textarea"
            readOnly
            rows={5}
            value={JSON.stringify(error)}
          />
        </>
      ),
    });

  const updateFileData = newData => {
    if (newData?.[0])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setFileData(newData.map(({ _id, ...validData }) => validData));
  };

  const validateRequiredFields = useCallback(
    () => Object.values(fieldMap ?? {}).length >= 1,
    [fieldMap],
  );

  useEffect(() => {
    if (file) {
      setLoading(true);
      if (fileExtension === 'csv')
        Papa.parse(file, {
          header: hasHeaderRow,
          skipEmptyLines: true,
          complete: results => {
            if (hasHeaderRow) updateFileData(results.data);
            else {
              updateFileData(
                results.data.map(row =>
                  row
                    .map((field, index) => {
                      return {
                        [`column_${'0'.repeat(
                          3 - index.toString().length,
                        )}${index}`]: field,
                      };
                    })
                    .reduce((p, c) => {
                      return { ...p, ...c };
                    }, {}),
                ),
              );
            }

            if (results?.errors?.[0]) {
              setMessage({
                isError: true,
                title: 'Import',
                message: (
                  <>
                    <div className="mb-3">
                      CSV may have loaded with errors. Contact data may be
                      corrupt.
                    </div>
                    <div className="mb-3">
                      <strong>Row:</strong>&nbsp;
                      {results.errors[0].row}
                    </div>
                    <div className="mb-3">
                      <strong>Code:</strong>&nbsp;
                      {results.errors[0].code}
                    </div>
                    <div className="mb-3">
                      <strong>Message:</strong>&nbsp;
                      {results.errors[0].message}
                    </div>
                  </>
                ),
              });
            }

            setLoading(false);
          },
        });
      else if (fileExtension === 'xlsx')
        readXlsxFile(file, { dateFormat: 'mm/dd/yyyy' }).then(results => {
          const headerRow = hasHeaderRow
            ? results.shift()
            : '*'
                .repeat(results[0].length)
                .split('')
                .map(
                  (value, index) =>
                    `column_${'0'.repeat(3 - index.toString().length)}${index}`,
                );

          updateFileData(
            results.map(row =>
              row
                .map((field, index) => {
                  return { [headerRow?.[index] as string]: field };
                })
                .reduce((p, c) => {
                  return { ...p, ...c };
                }, {}),
            ),
          );

          setLoading(false);
        });
    }
  }, [file, fileExtension, hasHeaderRow]);

  useEffect(() => {
    if (fileData) {
      const newExampleData = {};

      Object.keys(fileData[0]).forEach(key => {
        for (let c = 0; c < Math.min(3, fileData.length); c++) {
          const isLast = c === fileData.length - 1;

          if (
            fileData?.[c]?.[key] &&
            (fileData?.[c]?.[key]?.toString()?.replace(/[0 ]/, '') || isLast)
          )
            newExampleData[key] = [
              ...(newExampleData[key] ?? []),
              fileData[c][key],
            ];
        }

        newExampleData[key] = [
          ...Array.from(new Set(newExampleData[key] || [])),
        ]
          .slice(0, 10)
          .sort();
      });

      setExampleData(newExampleData);
    }
  }, [fileData]);

  useEffect(() => {
    if (exampleData && !fieldMap) setFieldMap(autoFill());
  }, [autoFill, exampleData, fieldMap]);

  useEffect(() => {
    if (!!fieldMap) setEnableMatchButton(validateRequiredFields());
  }, [fieldMap, validateRequiredFields]);

  useEffect(() => {
    if (!!userLogged?.organizationId)
      getOneOrganization({ Organization_ID: userLogged?.organizationId }).then(
        response => {
          setOrganization(response);
          setCredits(orgCredits(response));
        },
      );
  }, [userLogged?.organizationId]);

  return (
    <Container fluid className="pt-5">
      <LoadingOverlay show={loading} />

      {message && (
        <GeneralModal {...message} show onHide={() => setMessage(null)} />
      )}

      <GeneralConfirmationModal
        isError
        message={
          <>
            {matchCount?.toLocaleString()} matches will be downloaded for{' '}
            {matchCount?.toLocaleString()} credits. You currently have{' '}
            {credits?.toLocaleString()} credits.
            <strong>This action cannot be undone.</strong>
          </>
        }
        onHide={() => setMatchCount(null)}
        onOK={onGenerateCSV}
        show={!!matchCount && matchCount >= 0}
        title="Generate CSV"
      />

      <div className="fs-5 fw-bold mb-3">
        Select a CSV or Excel file to match.
      </div>

      <div className="fs-5 mb-3">
        Once you select a file, you will have the opportunity to specify if the
        file has a header row and map the columns.
      </div>

      <Row className="mb-5">
        <Col className="mb-3 mb-md-0" md={3}>
          <FormGroup controlId="formFile">
            <FormControl
              ref={fileRef}
              type="file"
              onChange={onFileSelect}
              accept=".xls,.xlsx,.csv"
            />
            <Hint>Supported extensions include: .csv, .xlsx</Hint>
          </FormGroup>
        </Col>

        {fileData && (
          <Col className="mb-3 mb-md-0" md={3}>
            <Toggle
              onChange={setHasHeaderRow}
              labelOff="No Header Row"
              labelOn="Has Reader Row"
              value={hasHeaderRow}
            ></Toggle>
          </Col>
        )}
      </Row>

      {fileData && (
        <div className="fs-5 fw-bold mb-3">
          Map{' '}
          {(fileExtension ?? '').toUpperCase() === 'CSV'
            ? 'CSV'
            : 'Spreadsheet'}{' '}
          columns.
        </div>
      )}

      {fileData && (
        <div className="fs-5 mb-3">
          Specify the type of data contained in each column. Only mapped columns
          will be considered when searching for matches. Mapping more columns
          may result in fewer matches with greater accuracy. Mapping less
          columns may result in more matches with lesser accuracy.
        </div>
      )}

      {!!fileData && (
        <Row>
          {Object.keys(fileData?.[0])
            .sort((a, b) =>
              a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
            )
            .map(key => (
              <Col key={key} md={3} className="mb-3">
                <FieldMappingDropdown
                  key={key}
                  exampleData={exampleData ?? {}}
                  field={key}
                  fieldMap={fieldMap ?? {}}
                  setFieldMap={setFieldMap}
                />
              </Col>
            ))}
        </Row>
      )}

      {!!fileData && (
        <Row className="mt-3">
          <Col md={11}></Col>
          <Col md={1}>
            <Button
              disabled={!enableMatchButton}
              className="w-100"
              onClick={() => onUpload()}
            >
              Find Matches
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default MOADBMatch;
