import * as React from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
//import Footer from 'app/components/Footer';
//import { SearchPage } from '../SearchPage/Loadable';
//import { ResultsPage } from '../ResultsPage/Loadable';
//import { DonorPage } from '../DonorPage/Loadable';
import { DashboardPage } from '../DashboardPage/Loadable';
import { CartPage } from '../CartPage/Loadable';
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { PrivacyPage } from '../PrivacyPage/Loadable';
import StripePaymentPage from '../StripePaymentPage';
import SucceededPage from '../SucceededPage';
import MainPage from 'app/components/Prospect-Tracker/pages/main';
import ProspectImporter from 'app/components/Prospect-Importer';
import { MOADBSearch } from 'app/components/MOADBSearch';
import P2PMessageQueue from 'app/components/P2PMessageQueue';
import ProcessMonitor from 'app/components/Process-Monitor';
import SMTPForm from 'app/components/SMTPForm';
import SMTPManager from 'app/components/Prospect-Tracker/components/SMTPManager';
import TemplateManager from 'app/components/Prospect-Tracker/components/TemplateManager';
import Reports from 'app/components/Reports';
import ScraperScript from 'app/components/ScraperScript';
import Settings from 'app/components/Settings';
import AcceptToS from '../AcceptTC';
import MOADBMatch from 'app/components/MOADBMatch';
import LeftNavigation from 'app/components/LeftNavigation';
import { useMediaQuery } from '@mui/material';
import Footer from 'app/components/Footer';
import { Container } from 'react-bootstrap';
import { MOADBFacetedSearch } from 'app/components/MOADBFacetedSearch';

export function HomePage() {
  const location = useLocation();
  const currentUser = useSelector(getAuthUser);
  const isMobile = useMediaQuery(`( max-width: 576px )`);

  const element = useRoutes([
    {
      path: '/login',
      element: <Navigate to="/" replace />,
    },
    {
      path: '/',
      element: <DashboardPage />,
    },
    /*{
      path: '/search',
      element: <SearchPage />,
    },
    {
      path: '/search/results',
      element: <ResultsPage />,
    },
    {
      path: '/search/results/:id',
      element: <DonorPage />,
    },*/
    {
      path: '/cart',
      element: <CartPage />,
    },
    {
      path: '/privacy',
      element: <PrivacyPage />,
    },
    {
      path: '/about',
      element: <h1>About</h1>,
    },
    {
      path: '/faq',
      element: <h1>FAQ</h1>,
    },
    {
      path: '/terms',
      element: <h1>Terms</h1>,
    },
    {
      path: '/privacy',
      element: <h1>Privacy</h1>,
    },
    {
      path: '/contact',
      element: <h1>Contact</h1>,
    },
    {
      path: '/blog',
      element: <h1>Blog</h1>,
    },
    {
      path: '/checkout',
      element: <StripePaymentPage />,
    },
    {
      path: '/disclaimers',
      element: <h1>Disclaimers</h1>,
    },
    {
      path: '/match',
      element: <MOADBMatch />,
    },
    {
      path: '/myprospects-list',
      element: <MOADBFacetedSearch searchPurchased={true} showMap={false} />,
    },
    {
      path: '/myprospects-map',
      element: <MOADBFacetedSearch searchPurchased={true} />,
    },
    {
      path: '/process-monitor',
      element: <ProcessMonitor />,
    },
    {
      path: '/prospect-tracker',
      element: <MainPage />,
    },
    {
      path: '/prospect-importer',
      element: <ProspectImporter />,
    },
    {
      path: '/moadb-search',
      element: <MOADBSearch />,
    },
    {
      path: '/p2p-message-queue',
      element: <P2PMessageQueue />,
    },
    {
      path: '/reports',
      element: <Reports />,
    },
    {
      path: '/scraper-script',
      element: <ScraperScript />,
    },
    {
      path: '/smtp-form',
      element: <SMTPForm />,
    },
    {
      path: '/smtp-manager',
      element: <SMTPManager />,
    },
    {
      path: '/template-manager',
      element: <TemplateManager />,
    },
    {
      path: '/search',
      element: <MOADBFacetedSearch searchPurchased={false} />,
    },
    {
      path: '/settings',
      element: <Settings />,
    },
    {
      path: '/thank-you',
      element: <SucceededPage />,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);

  if (!element) return null;

  return (
    <>
      <LeftNavigation />
      <div
        style={{
          marginLeft: isMobile ? '0px' : 'calc(0.75rem * 2 + 16px)',
        }}
      >
        <Container fluid className="mt-3">
          {!!currentUser?._id && !currentUser?.acceptedToS ? (
            <AcceptToS user={currentUser} />
          ) : (
            React.cloneElement(element, { key: location.pathname })
          )}
          <Footer />
        </Container>
      </div>
    </>
  );
}
