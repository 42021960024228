import React, { useEffect, useState } from 'react';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useAuthSliceSlice } from 'store/authentication/authentication';
import { useNavigate } from 'react-router-dom';
import LeftNavigationButton from './LeftNavigationButton';
import {
  BarChart,
  DoorClosed,
  FileText,
  Gear,
  GearWideConnected,
  Globe,
  Incognito,
  List,
  Map,
  People,
  Search,
  TelephoneOutbound,
  Upload,
} from 'react-bootstrap-icons';
import PATTERNIQ_LOGO from '../../assets/patterniq_logo.png';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import { BiCopy, BiHome } from 'react-icons/bi';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { getDonorsPurchasedCount } from 'api/donorlist.service';
import { useSearchSlice } from 'app/pages/SearchPage/slice';
import {
  selectHasPurchases,
  selectHasIngested,
} from 'app/pages/SearchPage/slice/selectors';
import { useMediaQuery } from '@mui/material';

export const LeftNavigation = () => {
  const userLogged = useSelector(getAuthUser);

  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();
  const {
    actions: { makeLogout },
  } = useAuthSliceSlice();
  const {
    actions: { setHasPurchasesIngested },
  } = useSearchSlice();
  const hasPurchases = useSelector(selectHasPurchases);
  const hasIngested = useSelector(selectHasIngested);
  const navigate = useNavigate();
  const [isAdmin] = useState(userLogged?.role === 'admin');
  const isMobile = useMediaQuery(`( max-width: 576px )`);

  const generalButtons = () => {
    const { pathname } = new URL(window.location.href);

    console.log('debug', pathname);

    return (
      <>
        {!isAdmin && (
          <LeftNavigationButton
            isActive={pathname === '/'}
            isMobile={isMobile}
            onClick={() => navigate('/')}
          >
            <BiHome className="me-2" /> Home
          </LeftNavigationButton>
        )}

        {!isAdmin && (
          <LeftNavigationButton
            isActive={pathname === '/search'}
            isMobile={isMobile}
            onClick={() => navigate('/search')}
          >
            <Search className="me-2" /> Search New Prospects
          </LeftNavigationButton>
        )}

        {!isAdmin && (
          <LeftNavigationButton
            isActive={pathname === '/myprospects-list'}
            isMobile={isMobile}
            onClick={() => navigate('/myprospects-list')}
          >
            <List className="me-2" /> List My Prospects
          </LeftNavigationButton>
        )}

        {!isAdmin && (
          <LeftNavigationButton
            isActive={pathname === '/myprospects-map'}
            isMobile={isMobile}
            onClick={() => navigate('/myprospects-map')}
          >
            <Map className="me-2" /> Map My Prospects
          </LeftNavigationButton>
        )}

        {false && !isAdmin && (
          <LeftNavigationButton
            isActive={pathname === '/match'}
            isMobile={isMobile}
            onClick={() => navigate('/match')}
          >
            <BiCopy className="me-2" /> Match
          </LeftNavigationButton>
        )}

        {false && !isAdmin && userLogged?.canAccessMOADBQuery && (
          <LeftNavigationButton
            isActive={pathname === '/p2p-message-queue'}
            isMobile={isMobile}
            onClick={() => navigate('/p2p-message-queue')}
          >
            <TelephoneOutbound className="me-2" /> P2P Message Queue
          </LeftNavigationButton>
        )}

        {!isAdmin && userLogged?.canAccessMOADBQuery && (
          <LeftNavigationButton
            isActive={pathname === '/process-monitor'}
            isMobile={isMobile}
            onClick={() => navigate('/process-monitor')}
          >
            <GearWideConnected className="me-2" /> Process Monitor
          </LeftNavigationButton>
        )}

        {!isAdmin && userLogged?.canAccessMOADBQuery && (
          <LeftNavigationButton
            isActive={pathname === '/prospect-importer'}
            isMobile={isMobile}
            onClick={() => navigate('/prospect-importer')}
          >
            <Upload className="me-2" /> Prospect Importer
          </LeftNavigationButton>
        )}

        {!isAdmin && (hasPurchases || hasIngested) && (
          <LeftNavigationButton
            isActive={pathname === '/prospect-tracker'}
            isMobile={isMobile}
            onClick={() => navigate('/prospect-tracker')}
          >
            <People className="me-2" /> Prospect Tracker
          </LeftNavigationButton>
        )}

        {!isAdmin && userLogged?.canAccessMOADBQuery && (
          <LeftNavigationButton
            isActive={pathname === '/reports'}
            isMobile={isMobile}
            onClick={() => navigate('/reports')}
          >
            <BarChart className="me-2" /> Reports
          </LeftNavigationButton>
        )}

        {!isAdmin && userLogged?.canAccessMOADBQuery && (
          <LeftNavigationButton
            isActive={pathname === '/scraper-script'}
            isMobile={isMobile}
            onClick={() => navigate('/scraper-script')}
          >
            <Globe className="me-2" /> Scraper
          </LeftNavigationButton>
        )}

        {!isAdmin && (
          <LeftNavigationButton
            isActive={pathname === '/settings'}
            isMobile={isMobile}
            onClick={() => navigate('/settings')}
          >
            <Gear className="me-2" /> Manage Subscriptions
          </LeftNavigationButton>
        )}

        <LeftNavigationButton isMobile={isMobile} onClick={handleLogout}>
          <DoorClosed className="me-2" /> Logout
        </LeftNavigationButton>

        <div className="footer">
          <LeftNavigationButton
            isMobile={isMobile}
            onClick={() =>
              window.open('https://www.dredata.ai/terms', '_blank')
            }
          >
            <FileText className="me-2" /> Terms of Use
          </LeftNavigationButton>

          <LeftNavigationButton
            isMobile={isMobile}
            onClick={() =>
              window.open('https://www.dredata.ai/privacy', '_blank')
            }
          >
            <Incognito className="me-2" /> Privacy Policy
          </LeftNavigationButton>
        </div>
      </>
    );
  };

  const handleLogout = () => {
    removeCookie('user');
    dispatch(makeLogout());
  };

  useEffect(() => {
    if (userLogged?.organizationId) {
      getDonorsPurchasedCount(userLogged!.email).then(result => {
        dispatch(
          setHasPurchasesIngested({
            hasPurchases:
              parseInt((result?.data as any)?.purchasedCount, 10) >= 1,
            hasIngested:
              parseInt((result?.data as any)?.ingestedCount, 10) >= 1,
          }),
        );
      });
    }
  }, [dispatch, setHasPurchasesIngested, userLogged]);

  return isMobile ? (
    <Navbar expand="lg" className="leftNavigation">
      <Container>
        <Navbar.Brand href="#home">
          <Image fluid className="me-2" src={PATTERNIQ_LOGO} /> PatternIQ
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">{generalButtons()}</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  ) : (
    <div className="leftNavigation shadow">
      <LeftNavigationButton onClick={() => navigate('/')}>
        <Image fluid className="me-2" src={PATTERNIQ_LOGO} /> PatternIQ
      </LeftNavigationButton>
      {generalButtons()}
    </div>
  );
};

export default LeftNavigation;
