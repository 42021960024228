import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import AddListModal from './AddListModal';
import { Collapsable } from '../Collapse';
import ListManager from './ListManager';
import EditListModal from './EditListModal';
import ToolTip from '../ToolTip';

const ListDashboard = ({
  handleDownloadCSV,
  includePolitical,
  organization,
  prospects,
  revealedColumns,
  setOrganization,
  setRevealedColumns,
  showContactInfo,
  userLogged,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState<any>(null);

  return (
    <>
      <AddListModal
        organization={organization}
        onHide={() => setShowAddModal(false)}
        setOrganization={setOrganization}
        show={showAddModal}
        userLogged={userLogged}
      />

      {!!showEditModal && (
        <EditListModal
          list={showEditModal}
          organization={organization}
          onHide={() => setShowEditModal(null)}
          setOrganization={setOrganization}
          show={!!showEditModal}
          userLogged={userLogged}
        />
      )}

      <div className="pe-3 text-end">
        <ButtonGroup>
          <ToolTip title="Generate CSV">
            <Button size="sm" onClick={handleDownloadCSV}>
              Download CSV
            </Button>
          </ToolTip>

          <ToolTip title="Create a new list">
            <Button onClick={() => setShowAddModal(true)}>
              Create New List
            </Button>
          </ToolTip>
        </ButtonGroup>
      </div>

      <Collapsable className="px-3">
        <ListManager
          allowRemove={false}
          defaultList={{
            name: 'All My Prospects',
            description: '',
            prospectId: prospects.map(prospect => prospect.id),
          }}
          includePolitical={includePolitical}
          listId={null}
          organization={organization}
          prospects={prospects}
          revealedColumns={revealedColumns}
          setOrganization={setOrganization}
          setRevealedColumns={setRevealedColumns}
          setShowEditModal={null}
          showContactInfo={showContactInfo}
          userLogged={userLogged}
        />

        {(organization.prospect_list ?? [])
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((list: any) => (
            <ListManager
              key={list.id}
              allowRemove
              defaultList={null}
              includePolitical={includePolitical}
              listId={list.id}
              organization={organization}
              prospects={prospects}
              revealedColumns={revealedColumns}
              setOrganization={setOrganization}
              setRevealedColumns={setRevealedColumns}
              setShowEditModal={setShowEditModal}
              showContactInfo={showContactInfo}
              userLogged={userLogged}
            />
          ))}
      </Collapsable>
    </>
  );
};

export default ListDashboard;
