import React, { useState } from 'react';
import './styles.css';
import { DashSquare, PlusSquare } from 'react-bootstrap-icons';

export const Collapsable = ({
  children,
  className = '',
}: {
  children?: any;
  className?: string;
}) => {
  return <div className={`collapsable ${className}`}>{children}</div>;
};

export const CollapsableBody = ({
  children,
  className = '',
}: {
  children?: any;
  className?: string;
}) => {
  return <div className={`collapsableBody pt-2 ${className}`}>{children}</div>;
};

export const CollapsableHeader = ({
  children,
  className = '',
  isCollapsed = true,
  onToggle,
  options,
}: {
  children?: any;
  className?: string;
  isCollapsed?: boolean;
  onToggle?: any;
  options?: any;
}) => {
  const [collapsed, setCollapsed] = useState(isCollapsed);

  return (
    <div
      className={`collapsableHeader border-bottom py-3 pb-1 ${className}`}
      onClick={() => {
        setCollapsed(!collapsed);
        if (!!onToggle) onToggle(!collapsed);
      }}
      data-collapsed={collapsed}
    >
      <span className="left"> {children}</span>
      <span className="right">
        {!!options && <span className="me-3">{options}</span>}
        {collapsed && <PlusSquare />}
        {!collapsed && <DashSquare />}
      </span>
    </div>
  );
};

export const CollapsableItem = ({
  children,
  className = '',
}: {
  children?: any;
  className?: string;
}) => {
  return <div className={`collapsableItem ${className}`}>{children}</div>;
};
