import ToolTip from 'app/components/ToolTip';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';

const StepIMAP = ({ imap, setImap }) => {
  return (
    <ButtonGroup vertical className="w-100">
      <ToolTip title="email@business.com" placement="top">
        <Button
          size="lg"
          variant={imap === 1 ? 'primary' : 'outline-dark'}
          className="fs-3"
          onClick={() => setImap(1)}
        >
          email@business.com
        </Button>
      </ToolTip>

      <ToolTip title="email@gmail.com" placement="top">
        <Button
          size="lg"
          variant={imap === 2 ? 'primary' : 'outline-dark'}
          className="fs-3"
          onClick={() => setImap(2)}
        >
          email@gmail.com
        </Button>
      </ToolTip>

      <ToolTip title="2ndemail@yahoo.com" placement="top">
        <Button
          size="lg"
          variant={imap === 3 ? 'primary' : 'outline-dark'}
          className="fs-3"
          onClick={() => setImap(3)}
        >
          2ndemail@yahoo.com
        </Button>
      </ToolTip>

      <ToolTip title="Add Email" placement="top">
        <Button size="lg" disabled variant="outline-dark" className="fs-3">
          Add Email <PlusCircle />
        </Button>
      </ToolTip>
    </ButtonGroup>
  );
};

export default StepIMAP;
