import { generateAILetter } from 'api/chatgpt.service';
import React, { useState } from 'react';
import { Alert, Button, ButtonGroup, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { getIdeologyLabel } from '../Prospect-Tracker/utility';
import LoadingOverlay from '../LoadingOverlay';
//import { sendLetter } from 'api/handwrytten.service';
import { toast } from 'react-toastify';
//import { useConfirmation } from 'custom-hooks/useConfirmation';
//import constants from 'app/config/constants';
import AIGenerationForm from '../AIGenerationForm/indext';

const AIGenerationModal = ({
  aiLetterSubject,
  credits,
  onHide = () => {},
  organization,
  setOrganization,
  show = false,
}) => {
  const userLogged = useSelector(getAuthUser);

  //const { confirm, ConfirmationModal } = useConfirmation();
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState(null);
  const [text, setText] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [recipient] = useState(
    `${aiLetterSubject?.first_name} ${aiLetterSubject?.last_name}`,
  );
  const [step, setStep] = useState(1);

  const handleCancel = () => {
    setStep(1);
    onHide();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  const handleGenerateLetter = async () => {
    setLoading(true);

    if (!error) {
      const applicableImportanInterests = (
        data?.importantInterests ?? []
      ).filter(interest => !!aiLetterSubject?.[interest]);

      const response = await generateAILetter({
        applicableImportanInterests,
        city: aiLetterSubject.city,
        email: userLogged?.email,
        income: aiLetterSubject.household_income_total,
        politics: getIdeologyLabel(parseInt(aiLetterSubject.ideology, 10)),
        recipient,
        religion: aiLetterSubject.modeled_religion,
        state: aiLetterSubject.state,
        ...(data ?? {}),
      });

      setOrganization(response.data.organization);
      setText(response.data.text);
      setStep(2);
    }

    setLoading(false);
  };

  /*const handleSendLetter = async () => {
    setLoading(true);

    sendLetter({
      email: userLogged?.email,
      message: text,
      recipient: {
        firstName: aiLetterSubject.first_name,
        lastName: aiLetterSubject.last_name,
        address1: aiLetterSubject.address_1,
        address2: aiLetterSubject.address_2,
        city: aiLetterSubject.city,
        state: aiLetterSubject.state,
        zip: aiLetterSubject.postal_code,
      },
      sender: data?.sender,
    })
      .then(response => {
        if ((response as any)?.data?.result?.mail_sent === true)
          toast.success('Card sent successfully!');
      })
      .finally(() => setLoading(false));
  };*/

  return (
    <>
      {/*ConfirmationModal*/}

      <LoadingOverlay show={loading} />

      <Modal size="lg" show={!!aiLetterSubject && show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>A.I. Generated Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 1 ? (
            <AIGenerationForm
              aiLetterSubject={aiLetterSubject}
              organization={organization}
              onChange={(data, error) => {
                setData(data);
                setError(error);
              }}
            />
          ) : (
            <>
              <div className="mb-3">
                View your A.I. Generated text. Please, make any changes you'd
                like.
              </div>

              <Form.Control
                className="mb-3"
                as="textarea"
                rows={20}
                value={text ?? ''}
                onChange={e => setText(e.target.value)}
              />
            </>
          )}
          {!!error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          {step === 1 ? (
            <ButtonGroup>
              <Button variant="light" className="border" onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleGenerateLetter}>Generate</Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button variant="light" className="border" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                variant="light"
                className="border"
                onClick={() => setStep(1)}
              >
                Back
              </Button>
              <Button onClick={handleGenerateLetter}>Regenerate</Button>
              <Button onClick={handleCopy}>Copy</Button>
              {/*<Button
                onClick={() =>
                  confirm(
                    'Send a Card',
                    <>
                      A card will be mailed for{' '}
                      {constants.PARCEL_CARD_CREDITS()} credits. You currently
                      have {credits.toLocaleString()} credits.
                      <strong>This action cannot be undone.</strong>
                    </>,
                    handleSendLetter,
                  )
                }
              >
                Send
              </Button>*/}
            </ButtonGroup>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AIGenerationModal;
