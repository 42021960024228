import React from 'react';
import { Badge, Col, Row } from 'react-bootstrap';

const StepIndicator = ({ className = '', labels, step }) => {
  return (
    <Row className={`align-items-center ${className}`}>
      {labels.map((label, index, array) => (
        <>
          <Col key={label} className="text-center">
            <h2>
              <Badge pill bg={index === step - 1 ? 'primary' : 'secondary'}>
                {label}
              </Badge>
            </h2>
          </Col>
          {index < array.length - 1 && (
            <Col>
              <hr />
            </Col>
          )}
        </>
      ))}
    </Row>
  );
};

export default StepIndicator;
