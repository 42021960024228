import LeftSidebar from 'app/components/LeftSidebar';
import { MenuTab } from 'app/components/LeftSidebar/types';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import AdminOrgs from '../AdminOrgs';
import { useSearchSlice } from '../SearchPage/slice';
import { selectActiveTab } from '../SearchPage/slice/selectors';
import UsersPage from '../UsersPage';
import { ADMIN_ROUTES } from './adminRoutes';
import LeftNavigation from 'app/components/LeftNavigation';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from '@mui/material';
import Footer from 'app/components/Footer';

const menuTabs: MenuTab[] = [
  {
    label: 'Organizations',
    logo: '',
    url: ADMIN_ROUTES.ORGS_ROUTE,
  },
  {
    label: 'Users',
    logo: '',
    url: `${ADMIN_ROUTES.USERS_ROUTE}?organizationId=""`,
  },
];
const AdminHome = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { actions } = useSearchSlice();
  const navigate = useNavigate();
  const activeTab = useSelector(selectActiveTab);
  const isMobile = useMediaQuery(`( max-width: 1224px )`);

  useEffect(() => {
    switch (location.pathname) {
      case ADMIN_ROUTES.USERS_ROUTE:
        dispatch(actions.setActiveTab('Users'));
        break;
      case ADMIN_ROUTES.ORGS_ROUTE:
        dispatch(actions.setActiveTab('Organizations'));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const element = useRoutes([
    {
      path: ADMIN_ROUTES.ORGS_ROUTE,
      element: <AdminOrgs />,
    },
    {
      path: ADMIN_ROUTES.USERS_ROUTE,
      element: <UsersPage />,
    },
    {
      path: '*',
      //Temporarily redirecting to AdminOrgs. So that the user doesn't see a 404
      element: <AdminOrgs />, //<NotFoundPage />,
    },
  ]);
  if (!element) return null;

  const handleSetActive = (tab: MenuTab) => {
    dispatch(actions.setActiveTab(tab.label));
    console.log('tab', tab);
    const urlTab = tab.url;
    console.log('urlTab', urlTab);
    if (urlTab) {
      navigate(urlTab);
    }
  };

  return (
    <>
      <LeftNavigation />
      <div
        style={{
          minHeight: 'calc(100vh - 150px)',
          marginLeft: isMobile ? '0px' : '64px',
          width: isMobile ? '100vw' : 'calc(100vw - (0.75rem * 2 + 24px))',
        }}
      >
        <Row className="pt-3">
          <Col md={1}>
            <LeftSidebar
              activeTab={activeTab}
              setActiveTab={handleSetActive}
              menuTabs={menuTabs}
            />
          </Col>
          <Col md={11}>
            <AnimatePresence mode="wait">
              {React.cloneElement(element, { key: location.pathname })}
            </AnimatePresence>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default AdminHome;
