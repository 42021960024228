import * as React from 'react';

const Footer = ({ color = 'dark', width = '100vw' }) => (
  <div
    className="fs-5 mt-5 text-center"
    style={{
      height: '75px',
      width,
    }}
  >
    <div>Copyright © DRE Data Services LLC {new Date().getFullYear()}</div>
    <a
      className={`fs-5 ms-3 p-0 text-${color}`}
      href="https://www.dredata.ai/terms"
      target="_blank"
      rel="noreferrer"
    >
      Terms of Use
    </a>
    <a
      className={`fs-5 ms-3 p-0 text-${color}`}
      href="https://www.dredata.ai/privacy"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
  </div>
);

export default Footer;
