import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';

import React, { useRef, useState } from 'react';
import { FeatureGroup, MapContainer, TileLayer } from 'react-leaflet';
import ProspectModal from './ProspectModal';
import { Cluster } from './Cluster';
import { Icon } from 'leaflet';

export const MOADBMap = ({
  credits,
  data,
  onColumnFilter,
  onColumnHeader,
  purchasedIcon,
  searchPurchased,
  setCreditsDelta,
  setMarkSingleProspect,
  setAILetterSubject,
  setSuggestionTarget,
  showContactInfo,
}: {
  credits: number;
  data?: any[];
  onColumnFilter?: any;
  onColumnHeader?: any;
  purchasedIcon?: Icon;
  searchPurchased: boolean;
  setCreditsDelta: any;
  setMarkSingleProspect: any;
  setAILetterSubject?: any;
  setSuggestionTarget: any;
  showContactInfo: boolean;
}) => {
  const [prospect, setProspect] = useState<any>(null);
  const dataSafe = data ?? [];

  const [center] = useState(
    !!data?.[0]
      ? {
          lat:
            dataSafe
              .filter(
                item =>
                  item.address_latitude !== '' && item.address_longitude !== '',
              )
              .map(item => parseFloat(item.address_latitude))
              .reduce((p, c) => p + c, 0) / (dataSafe?.length ?? 1),
          lon:
            dataSafe
              .filter(
                item =>
                  item.address_latitude !== '' && item.address_longitude !== '',
              )
              .map(item => parseFloat(item.address_longitude))
              .reduce((p, c) => p + c, 0) / (dataSafe?.length ?? 1),
        }
      : { lat: 37.0902, lon: -95.7129 },
  );

  const groupRef = useRef<L.FeatureGroup>(null);
  const isLoaded = useRef(false);
  const mapRef = useRef<L.Map>(null);

  return (
    <>
      {(!!showContactInfo || !!prospect?.dt_id) && (
        <ProspectModal
          data={prospect}
          dataAll={dataSafe}
          onColumnFilter={onColumnFilter}
          onColumnHeader={onColumnHeader}
          onHide={() => setProspect(null)}
          show={!!prospect}
        />
      )}

      <MapContainer
        center={[center.lat, center.lon]}
        zoom={5}
        scrollWheelZoom={true}
        className="w-100 h-100"
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          eventHandlers={{
            load() {
              if (
                !!data?.[0] &&
                !isLoaded.current &&
                !!mapRef?.current &&
                !!groupRef?.current
              ) {
                isLoaded.current = true;
                mapRef?.current.fitBounds(groupRef?.current.getBounds());
              }
            },
          }}
        />
        <FeatureGroup ref={groupRef}>
          {!!dataSafe?.[0] && (
            <Cluster
              credits={credits}
              data={dataSafe}
              mapRef={mapRef}
              purchasedIcon={purchasedIcon}
              searchPurchased={searchPurchased}
              setCreditsDelta={setCreditsDelta}
              setMarkSingleProspect={setMarkSingleProspect}
              setProspect={setProspect}
              setAILetterSubject={setAILetterSubject}
              showContactInfo={showContactInfo}
              setSuggestionTarget={setSuggestionTarget}
              timer={1000}
            />
          )}
        </FeatureGroup>
      </MapContainer>
    </>
  );
};
