import ToolTip from 'app/components/ToolTip';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import {
  ArrowRight,
  ChatLeftDots,
  EnvelopeAt,
  PlusCircle,
  Telephone,
} from 'react-bootstrap-icons';

const StepSequence = ({ sequence, setSequence }) => {
  return (
    <ButtonGroup vertical className="w-100">
      <ToolTip title="Call/VM + Email + Text + Email" placement="top">
        <Button
          size="lg"
          variant={sequence === 1 ? 'primary' : 'outline-dark'}
          className="fs-1"
          onClick={() => setSequence(1)}
        >
          <Telephone />
          <ArrowRight className="mx-3" />
          <EnvelopeAt />
          <ArrowRight className="mx-3" />
          <ChatLeftDots />
          <ArrowRight className="mx-3" />
          <EnvelopeAt />
        </Button>
      </ToolTip>

      <ToolTip
        title="Personalized Email + General Content Email + Follow-Up Email"
        placement="top"
      >
        <Button
          size="lg"
          variant={sequence === 2 ? 'primary' : 'outline-dark'}
          className="fs-1"
          onClick={() => setSequence(2)}
        >
          <EnvelopeAt />
          <ArrowRight className="mx-3" />
          <EnvelopeAt />
          <ArrowRight className="mx-3" />
          <EnvelopeAt />
        </Button>
      </ToolTip>

      <ToolTip title="Add Sequence" placement="top">
        <Button size="lg" disabled variant="outline-dark" className="fs-3">
          Add Sequence <PlusCircle />
        </Button>
      </ToolTip>
    </ButtonGroup>
  );
};

export default StepSequence;
