import React, { useState } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';

const SelectListModal = ({ okLabel, organization, onHide, onOK, show }) => {
  const [selected, setSelected] = useState<any>(null);

  const handleOK = () => {
    setSelected(null);
    onOK(selected);
  };

  const handlToggle = list => {
    const index = (selected ?? []).indexOf(list);

    if (index >= 0) {
      selected.splice(index, 1);
      setSelected([...selected]);
    } else setSelected([...(selected ?? []), list]);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select List(s)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ButtonGroup vertical className="w-100">
          {(organization.prospect_list ?? []).map(list => (
            <Button
              variant={(selected ?? []).includes(list) ? 'dark' : 'light'}
              className="text-start w-100"
              onClick={() => handlToggle(list)}
            >
              <div>{list.name}</div>
              <small>{list.description}</small>
            </Button>
          ))}
        </ButtonGroup>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
          <Button
            disabled={!selected?.[0]}
            variant="primary"
            onClick={() => handleOK()}
          >
            {okLabel}
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectListModal;
