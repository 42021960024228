import constants from 'app/config/constants';
import {
  GoogleProfileWithTokens,
  UserSession,
} from 'store/authentication/types';
import axiosInstance from 'utils/api';
import { IUser } from './interfaces';

export const sendPasswordlessLogin = async (username: string) => {
  const endpoint = constants.SEND_PASSWORDLESS_LOGIN_ENDPOINT();
  const url = `${endpoint}`;
  return axiosInstance()<UserSession>({
    url,
    method: 'POST',
    data: { username, origin: window.location.origin },
  }).then(response => response.data);
};

export const signInUser = async (username: string, password: string) => {
  const endpoint = constants.SIGNIN_ENDPOINT();
  const url = `${endpoint}`;
  return axiosInstance()<UserSession>({
    url,
    method: 'POST',
    data: { username, password, origin: window.location.origin },
  }).then(response => response.data);
};

export const signInUserPasswordlessly = async (token: string) => {
  const endpoint = constants.SIGNIN_PASSWORDLESSLY_ENDPOINT();
  const url = `${endpoint}`;
  return axiosInstance()<UserSession>({
    url,
    method: 'POST',
    data: { token },
  }).then(response => response.data);
};

export const signInFederatedUser = async (
  username: string,
  providerProfile: GoogleProfileWithTokens,
) => {
  const endpoint = constants.FEDERATED_USERS_ENDPOINT();
  const url = `${endpoint}`;
  return axiosInstance()<UserSession>({
    url,
    method: 'POST',
    data: { username, ...providerProfile },
  }).then(response => response.data);
};

export const restoreSession = async sessionToken => {
  const endpoint = constants.SESSION_ENDPOINT();
  const url = `${endpoint}`;
  return axiosInstance(sessionToken)<UserSession>({
    url,
    method: 'POST',
  }).then(response => response.data);
};

export const registerUser = async (userBody: IUser) => {
  const endpoint = constants.SIGNUP_ENDPOINT();
  const url = `${endpoint}`;
  return axiosInstance()<UserSession>({
    url,
    method: 'POST',
    data: { ...userBody, origin: window.location.origin },
  }).then(response => response.data);
};

export const resetTempPassword = async (email: string) => {
  const endpoint = constants.RESET_TEMP_PASSWORD_ENDPOINT();
  const url = `${endpoint}`;
  return axiosInstance()<UserSession>({
    url,
    method: 'POST',
    data: { email, origin: window.location.origin },
  }).then(response => response.data);
};

export const resetPassword = async (username: string, password: string) => {
  const endpoint = constants.RESET_PASSWORD_ENDPOINT();
  const url = `${endpoint}`;
  return axiosInstance()<UserSession>({
    url,
    method: 'POST',
    data: { username, password, origin: window.location.origin },
  }).then(response => response.data);
};
