import React from 'react';
import './styles.css';
import ToolTip from '../ToolTip';

export const IdeologyMeter = ({ value = '3' }: { value?: string }) => {
  return (
    <div className="ideology">
      <ToolTip title="More Liberal" placement="top">
        <div className="handle blue"></div>
      </ToolTip>{' '}
      <div className={`indicator i${value}`}></div>
      <div className="bar"></div>
      <ToolTip title="More Conservative" placement="top">
        <div className="handle red"></div>
      </ToolTip>
    </div>
  );
};
