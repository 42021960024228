import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import ToolTip from '../ToolTip';
import { getMOADBStates, markAsPurchased, queryMOADB } from 'api/moadb.service';
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  ArrowDownSquare,
  ArrowDownSquareFill,
  ArrowUpSquare,
  CheckSquare,
  InfoSquare,
  Square,
  Upload,
} from 'react-bootstrap-icons';
import { MOADBDropdownRegions } from '../MOADBDropdownRegions';
import { MOADBDropdownStates } from '../MOADBDropdownStates';
import { MOADBDropdownCustomers } from '../MOADBDropdownCustomers';
import { Alert, Card, Form, InputGroup } from 'react-bootstrap';
import LoadingOverlay from '../LoadingOverlay';
import { Toggle } from '../Toggle';
import GeneralConfirmationModal from '../GeneralConfirmationModal/GeneralConfirmationModal';
import generateCSV from 'utils/generateCSV';
import { MultiSelect } from '../MultiSelect';
import { InputSelect } from '../InputSelect';
import { AddressLookup } from '../AddressLookup';
import './styles.css';
import { InsetLabel } from '../InsetLabel';
import NewModal from './NewModal';
import OpenModal from './OpenModal';
import SaveModal from './SaveModal';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { toast } from 'react-toastify';
import {
  getFacetedSearches,
  insertFacetedSearchRecords,
} from 'api/faceted.search.service';
import FileDetailsModal from './FileDetailsModal';
import { MOADBMap } from '../MOADBMap';
import { useHistory } from 'custom-hooks/useHistory';
import { toFixed } from 'utils/toFixed';
import DataProfileModal from './DataProfileModal';
import { historyMask, moadbColumns, newFacets, purgeDTIDs } from './utils';
import { MOADBDropdownCounties } from '../MOADBDropdownCounties';
import UpdloadAndMarkAsPurchsedModal from './UpdloadAndMarkAsPurchsedModal';
import { sendSlackMessage } from 'api/slack.service';
import GeneralModal from '../GeneralModal/GeneralModal';
//import Catalog from '../Stripe';
import { getOneOrganization } from 'api/organization.service';
import { orgCredits } from 'utils/orgCredits';
import CreditsModal from './CreditsModal';
import { orgHasBasicAccess } from 'utils/orgHasBasicAccess';
import Preview from '../../assets/preview.png';
import PreviewLive from '../../assets/preview_live.png';
import { updateAnalytics } from 'utils/updateAnalytics';
import PurchasedIcon from 'app/assets/markerPurchased.png';
import PurchasedShadow from 'app/assets/shadowPurchased.png';
import L from 'leaflet';
import {
  Collapsable,
  CollapsableBody,
  CollapsableHeader,
  CollapsableItem,
} from '../Collapse';
import AIGenerationModal from '../AIGenerationModal';
import SuggestEditModal from './SuggestEditModal';
import ListDashboard from '../ListDashboard';
import GuideSection from './GuideSection';

export const MOADBFacetedSearch = ({
  searchPurchased = false,
  showMap = true,
}) => {
  const userLogged = useSelector(getAuthUser);
  const [organization, setOrganization] = useState<any>(null);
  const [credits, setCredits] = useState<any>(0);
  const [creditsDelta, setCreditsDelta] = useState<any>(0);
  const [error, setError] = useState<any>(null);
  const [includePolitical, setIncludePolitical] = useState<boolean>(false);
  const facets = useHistory(
    newFacets({
      customerId: !!userLogged ? userLogged.moadbCustomerId : null,
      hasPhone: true,
      ideology: [],
    }),
    historyMask(),
  );
  const [facetError, setFacetError] = useState<any>(null);
  const [latestSearchTime, setLatestSearchTime] = useState(Date.now());
  const [markedAsPurchased, setMarkedAsPurchased] = useState<boolean>(false);
  const [facetedSearches, setFacetedSearches] = useState<any>([]);
  const purchasedIcon = new L.Icon({
    iconUrl: PurchasedIcon,
    shadowUrl: PurchasedShadow,
  });
  const [results, setResults] = useState<any>(null);
  const [resultsAreLoading, setResultsAreLoading] = useState<boolean>(false);
  const [counted, setCounted] = useState(false);
  const [markSingleProspect, setMarkSingleProspect] = useState<any>(null);
  const [prospectListValue, setProspectListValue] = useState<any>(null);
  const [prospectListValueIsOption, setProspectListValueIsOption] =
    useState<any>(null);
  const [savingAs, setSavingAs] = useState<boolean>(false);
  const [aiLetterSubject, setAILetterSubject] = useState<any>(null);
  const [slackConfirmationMessage, setSlackConfirmationMessage] =
    useState<any>(null);
  const [slackConfirmationTitle, setSlackConfirmationTitle] =
    useState<any>(null);
  const [showContactInfo, setShowContactInfo] = useState<boolean>(
    ((userLogged?.permissions ?? []) as any).includes(
      'facetedSearchCanViewContactInfo',
    ) ?? false,
  );
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showFileDetailsModal, setShowFileDetailsModal] =
    useState<boolean>(false);
  const [showDataProfileModal, setShowDataProfileModal] = useState(false);
  const [showMarkModal, setShowMarkModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [showOpenModel, setShowOpenModal] = useState<boolean>(false);
  const [showPoliticalModal, setShowPoliticalModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [
    showUploadAndMarkAsPurchsedModal,
    setShowUploadAndMarkAsPurchsedModal,
  ] = useState(false);
  const [revealedColumns, setRevealedColumns] = useState(moadbColumns);
  const [states, setStates] = useState<any[]>([]);
  const [suggestionTarget, setSuggestionTarget] = useState<any>(null);

  /*const appendPurchaseHistory = payment => {
    const newOrg = {
      ...organization,
      paymentHistory: [...organization.paymentHistory, payment],
    };
    setOrganization(newOrg);
    setCredits(orgCredits(newOrg));
  };*/

  const clearConfirmationState = () => {
    setSlackConfirmationMessage(null);
    setSlackConfirmationTitle(null);
  };

  const handleDownloadCSV = () => {
    if (includePolitical) setShowDownloadModal(true);
    else onGenerateCSV();
  };

  const loadFacetedSearches = useCallback(() => {
    getFacetedSearches({
      email: userLogged!.email,
    }).then(response => {
      setFacetedSearches(response.data.facetedSearches);
    });
  }, [userLogged]);

  const onColumnFilter = useCallback(
    (column, forDownload = false) => {
      return (
        ![
          'email_deliverability_code_list',
          'email_list',
          'email_match_type_list',
          'estimated_rental_value',
          'home_value_estimated_at',
          'household_income_net',
          'moadb_voter_id_legacy',
          ...(forDownload
            ? ['address_latitude', 'address_longitude', 'birth_date', 'dt_id']
            : []),
        ].includes(column) &&
        (includePolitical ||
          ![
            'general_election_turnout',
            'jurisdiction_name',
            'modeled_party',
            'modeled_spectrum_brackets',
            'modeled_spectrum_score',
            'ideology',
            'state_house_district',
            'state_senate_district',
            'us_house_district',
            'voter_status',
          ].includes(column))
      );
    },
    [includePolitical],
  );

  const onColumnHeaderChanged = useCallback(
    column => {
      const index = revealedColumns.indexOf(column);

      if (index >= 0) revealedColumns.splice(index, 1);
      else revealedColumns.push(column);

      setRevealedColumns([...revealedColumns]);
    },
    [revealedColumns],
  );

  const onColumnHeader = useCallback(
    (column, includeChecks = true) => {
      let columnHeader = column
        .replace(/[^a-z0-9_]/gi, '')
        .replace(/_/g, ' ')
        .replace(/\s+/g, ' ')
        .trim();

      if (column === 'cell_phone_alesco') columnHeader = 'cell phone a';
      else if (column === 'cell_phone_hub224') columnHeader = 'cell phone b';
      else if (column === 'count_1') columnHeader = 'count';
      else if (column === 'landline_phone_alesco')
        columnHeader = 'landline_phone_a';
      else if (column === 'landline_phone__hub224')
        columnHeader = 'landline phone b';
      else if (column === 'moadb_voter_id') columnHeader = 'id';
      else if (column === 'other_phone_alesco') columnHeader = 'other phone a';

      console.log(
        'debug',
        revealedColumns.includes(column),
        column,
        revealedColumns,
      );

      return !includeChecks ||
        (!showContactInfo &&
          [
            'address_1',
            'address_2',
            'address_latitude',
            'address_longitude',
            'cell_phone_alesco',
            'cell_phone_hub224',
            'email_list',
            'landline_phone_alesco',
            'landline_phone__hub224',
            'other_phone_alesco',
          ].includes(column)) ? (
        columnHeader
      ) : (
        <Form.Check
          checked={revealedColumns.includes(column)}
          label={columnHeader}
          onChange={() => onColumnHeaderChanged(column)}
        />
      );
    },
    [onColumnHeaderChanged, revealedColumns, showContactInfo],
  );

  const onGenerateCSV = async () => {
    setShowDownloadModal(false);
    await onSearch(false, true);
  };

  const onMarkPurchased = async () => {
    setShowMarkModal(false);
    setResultsAreLoading(true);
    const dt_ids = purgeDTIDs(organization, results).map(row => row['id']);

    updateAnalytics('facetedSearchBatchLicense', {
      user: userLogged?.email,
      dt_ids: dt_ids,
    });

    markAsPurchased({
      customer_id: facets.current?.Facets?.customerId,
      dt_ids,
      organizationId: userLogged?.organizationId,
      prospectListValue,
      prospectListValueIsOption,
    }).then(response => {
      setResultsAreLoading(false);
      if (!!response?.data?.moadb?.purchase_record_id) {
        toast.info(`Prospects marked as licensed successfully`);
        setOrganization(response?.data?.organization);
        setCredits(orgCredits(response?.data?.organization));
        setMarkedAsPurchased(true);
        setResults(null);
      }
    });
  };

  const onMarkSinglePurchased = async () => {
    setMarkSingleProspect(null);
    setResultsAreLoading(true);
    const dt_ids = [markSingleProspect['id']];

    updateAnalytics('facetedSearchSingleLicense', {
      user: userLogged?.email,
      dt_ids: dt_ids,
    });

    markAsPurchased({
      customer_id: facets.current?.Facets?.customerId,
      dt_ids,
      organizationId: userLogged?.organizationId,
      prospectListValue,
      prospectListValueIsOption,
    }).then(response => {
      setResultsAreLoading(false);
      if (!!response?.data?.moadb?.purchase_record_id) {
        toast.info(`Prospect marked as licensed successfully`);
        results.forEach(row => {
          if (row.id === markSingleProspect.id) row.dt_id = row.id;
        });
        setOrganization(response?.data?.organization);
        setCredits(orgCredits(response?.data?.organization));
        setResults(results);
      }
    });
  };

  const onSearch = useCallback(
    async (
      markAsPurchased = false,
      download = false,
      customFacets: any = null,
      count = false,
    ) => {
      setCounted(count);
      const actualFacets = customFacets ?? facets.current;

      if (!actualFacets?.Facets?.customerId) setFacetError('Customer required');
      else if (!resultsAreLoading) {
        updateAnalytics('facetedSearchQuery', {
          user: userLogged?.email,
          ...actualFacets?.Facets,
        });

        setResultsAreLoading(true);
        setFacetError(null);
        queryMOADB(
          {
            count,
            customerId: actualFacets?.Facets?.customerId,
            includePolitical,
            limit:
              searchPurchased && !!organization?.prospects_purchased
                ? organization?.prospects_purchased?.length
                : actualFacets?.Facets?.limit,
            markAsPurchased,
            organizationId: organization.Organization_ID,
            order: actualFacets?.Facets?.order,
            purchased: searchPurchased,
            reveal: showContactInfo,
            sort: actualFacets?.Facets?.sort,
            time: Date.now(),
            sqlElements: [
              !!actualFacets?.Facets?.county?.[0]
                ? {
                    type: 'in',
                    field: 'JURISDICTION_NAME',
                    value: actualFacets?.Facets?.county,
                  }
                : null,
              actualFacets?.Facets?.hasEmail
                ? { type: 'is not null', field: 'PRIMARY_EMAIL', value: null }
                : null,
              actualFacets?.Facets?.hasPhone
                ? {
                    type: 'or',
                    children: [
                      {
                        type: 'is not null',
                        field: 'CELL_PHONE_HUB224',
                        value: null,
                      },
                      {
                        type: 'is not null',
                        field: 'LANDLINE_PHONE__HUB224',
                        value: null,
                      },
                    ],
                  }
                : null,
              !!actualFacets?.Facets?.interests?.[0]
                ? {
                    type: 'or',
                    children: actualFacets?.Facets?.interests
                      .map(interest => ({
                        type: '!=',
                        field: interest,
                        value: '',
                      }))
                      .reduce((p, c) => [...p, c], []),
                  }
                : null,
              actualFacets?.Facets?.is4YrGrad
                ? { type: '=', field: 'IS_4YR_GRADUATE', value: 'Yes' }
                : null,
              !!actualFacets?.Facets?.latLon
                ? [
                    {
                      type: 'is not null',
                      field: 'ADDRESS_LATITUDE',
                      value: null,
                    },
                    {
                      type: '!=',
                      field: 'ADDRESS_LATITUDE',
                      value: '',
                    },
                    {
                      type: 'is not null',
                      field: 'ADDRESS_LONGITUDE',
                      value: null,
                    },
                    {
                      type: '!=',
                      field: 'ADDRESS_LONGITUDE',
                      value: '',
                    },
                    {
                      type: 'distance',
                      value: {
                        ...{
                          lat: actualFacets?.Facets?.latLon.lat,
                          long: actualFacets?.Facets?.latLon.lon,
                        },
                        distance: actualFacets?.Facets?.distance,
                      },
                    },
                  ]
                : null,
              !!actualFacets?.Facets?.maxAge
                ? {
                    type: '>=',
                    field: 'BIRTH_DATE',
                    value: moment()
                      .subtract(actualFacets?.Facets?.maxAge, 'years')
                      .format('YYYY-MM-DD'),
                  }
                : null,
              !!actualFacets?.Facets?.minAge
                ? {
                    type: '<=',
                    field: 'BIRTH_DATE',
                    value: moment()
                      .subtract(actualFacets?.Facets?.minAge, 'years')
                      .format('YYYY-MM-DD'),
                  }
                : null,
              !!actualFacets?.Facets?.minDonationCapacity
                ? {
                    type: '>=',
                    field: 'DONATION_CAPACITY',
                    value: actualFacets?.Facets?.minDonationCapacity,
                  }
                : null,
              !!actualFacets?.Facets?.minEstimatedHomeValue
                ? {
                    type: '>=',
                    field: 'ESTIMATED_HOME_VALUE',
                    value: actualFacets?.Facets?.minEstimatedHomeValue,
                  }
                : null,
              !!actualFacets?.Facets?.minEstimatedIncome
                ? {
                    type: '>=',
                    field: 'HOUSEHOLD_INCOME_TOTAL',
                    value: actualFacets?.Facets?.minEstimatedIncome,
                  }
                : null,
              !!actualFacets?.Facets?.name
                ? {
                    type: 'or',
                    children: [
                      {
                        type: 'like',
                        field: 'first_name',
                        value: actualFacets?.Facets?.name,
                      },
                      {
                        type: 'like',
                        field: 'last_name',
                        value: actualFacets?.Facets?.name,
                      },
                      {
                        type: 'like',
                        field: ['first_name', 'last_name'],
                        value: actualFacets?.Facets?.name,
                      },
                      {
                        type: 'like',
                        field: ['last_name', 'first_name'],
                        value: actualFacets?.Facets?.name,
                      },
                    ],
                  }
                : null,

              !!actualFacets?.Facets?.ideology?.[0]
                ? {
                    type: 'in',
                    field: 'IDEOLOGY',
                    value: actualFacets?.Facets?.ideology,
                  }
                : null,
              !!actualFacets?.Facets?.religion?.[0]
                ? {
                    type: 'in',
                    field: 'MODELED_RELIGION',
                    value: actualFacets?.Facets?.religion,
                  }
                : null,
              !!actualFacets?.Facets?.state?.[0]
                ? {
                    type: 'in',
                    field: 'STATE',
                    value: actualFacets?.Facets?.state,
                  }
                : null,
            ]
              .filter(a => a)
              .reduce(
                (p: any, c: any) => [...p, ...(Array.isArray(c) ? c : [c])],
                [],
              ),
          },
          response => {
            const error = response?.[0]?.__error;

            if (!!error)
              setError(`MOADB Returned Error: ${decodeURIComponent(error)}`);
            else {
              setError(null);

              if (download)
                generateCSV({
                  data: response,
                  onColumnFilter: column => onColumnFilter(column, true),
                  onColumnHeader,
                });

              if (markAsPurchased) {
                setMarkedAsPurchased(true);
                setResults(null);
              } else {
                setMarkedAsPurchased(false);
                setResults(response);
              }
            }

            setResultsAreLoading(false);
            setLatestSearchTime(Date.now());
          },
        );
      }
    },
    [
      facets,
      includePolitical,
      onColumnFilter,
      onColumnHeader,
      organization,
      resultsAreLoading,
      searchPurchased,
      showContactInfo,
      userLogged?.email,
    ],
  );

  const openFacetedSearch = newFacets => {
    facets.update(newFacets);
    setShowNewModal(false);
    setShowOpenModal(false);
  };

  const saveFacetedSearch = (newFacets: any = null) => {
    const saveFacets = newFacets ?? facets.current;

    if (savingAs && !!saveFacets?._id) delete saveFacets._id;

    insertFacetedSearchRecords({
      email: userLogged!.email,
      facets: saveFacets,
    }).then(response => {
      facets.update(response.data.facets);
      setShowSaveModal(false);
      setSavingAs(false);
      loadFacetedSearches();
      toast.info(`"${saveFacets?.Name}" saved successfully`);
    });
  };

  const saveQueryAs = () => {
    setSavingAs(true);
    setShowSaveModal(true);
  };

  const saveQueryOrSaveQueryAs = () => {
    if (!!facets?.current?._id) saveFacetedSearch();
    else setShowSaveModal(true);
  };

  const requestSendingSlackMessage = (
    event,
    message,
    confirmationTitle,
    confirmationMessage,
  ) => {
    updateAnalytics(event, {
      user: userLogged?.email,
    });

    sendSlackMessage(
      userLogged?.email,
      [
        message,
        `email: ${userLogged?.email}`,
        `PatternIQ User ID: ${userLogged?._id}`,
        `PatternIQ Org ID: ${userLogged?.organizationId}`,
        `MOADB Customer ID: ${userLogged?.moadbCustomerId}`,
      ].join('\r\n'),
    ).then(response => {
      setSlackConfirmationTitle(confirmationTitle);
      setSlackConfirmationMessage(confirmationMessage);
    });
  };

  const updateFacets = useCallback(
    newFacets => {
      facets.update({
        ...newFacets,
      });
    },
    [facets],
  );

  const updateIncludePolitical = newValue => {
    setIncludePolitical(newValue);
    setResults(null);
  };

  useEffect(() => {
    loadFacetedSearches();
  }, [loadFacetedSearches, userLogged]);

  useEffect(() => {
    const newShowContactInfo =
      (!!searchPurchased ||
        ((userLogged?.permissions ?? []) as any).includes(
          'facetedSearchCanViewContactInfo',
        )) ??
      false;

    setShowContactInfo(newShowContactInfo);

    setRevealedColumns(moadbColumns);
  }, [searchPurchased, showContactInfo, userLogged?.permissions]);

  useEffect(() => {
    if (!!userLogged?.organizationId)
      getOneOrganization({ Organization_ID: userLogged?.organizationId }).then(
        response => {
          setOrganization(response);
          setCredits(orgCredits(response));
        },
      );
  }, [userLogged?.organizationId, latestSearchTime]);

  useEffect(() => {
    updateAnalytics('facetedSearchLoaded', {
      user: userLogged?.email,
    });
  }, [userLogged?.email]);

  useEffect(() => {
    getMOADBStates().then(response => setStates(response.data));
  }, []);

  useEffect(() => {
    if (
      searchPurchased &&
      !!facets &&
      !!organization &&
      !results &&
      !resultsAreLoading
    ) {
      console.log('debug', 'loading purchased...');
      onSearch(false, false, null, false);
    }
  }, [
    facets,
    onSearch,
    organization,
    results,
    resultsAreLoading,
    searchPurchased,
  ]);

  if (!organization) return null;

  /*if (!isOrganizationSubscribed(organization) && !organization.canBypassPaywall)
    return (
      <Catalog organization={organization} onPurchase={appendPurchaseHistory} />
    );*/

  return (
    <>
      <FileDetailsModal
        onHide={() => setShowFileDetailsModal(false)}
        facets={facets}
        updateFacets={updateFacets}
        show={showFileDetailsModal}
      />

      {!!results && (
        <GeneralConfirmationModal
          message={
            !!userLogged?.moadbCustomerId ? (
              <>
                {purgeDTIDs(organization, results).length.toLocaleString()}{' '}
                records will be licensed for{' '}
                {purgeDTIDs(organization, results).length.toLocaleString()}{' '}
                credits. You currently have {credits.toLocaleString()} credits.
                <strong>This action cannot be undone.</strong>
              </>
            ) : (
              <>
                {purgeDTIDs(organization, results).length.toLocaleString()}{' '}
                records will be marked as licensed for this customer for{' '}
                {purgeDTIDs(organization, results).length.toLocaleString()}{' '}
                credits. <strong>This action cannot be undone.</strong>
              </>
            )
          }
          onHide={() => setShowMarkModal(false)}
          onOK={onMarkPurchased}
          show={showMarkModal}
          title={
            !!userLogged?.moadbCustomerId ? 'License All' : 'Mark As Licensed'
          }
          footerComponents={
            <InsetLabel
              label="Add Prospect to List"
              info="Select a list from the dropdown or enter the name of a new list prospect should be added to."
            >
              <InputSelect
                disabled={!orgHasBasicAccess(organization)}
                className="mb-3"
                caption={
                  prospectListValueIsOption
                    ? (organization.prospect_list ?? []).filter(
                        prospectList => prospectList.id === prospectListValue,
                      )?.[0]?.name
                    : prospectListValue
                }
                value={prospectListValue}
                onChange={e => {
                  console.log('debug', e.target);
                  setProspectListValue(e.target.value);
                  setProspectListValueIsOption(e.target.isOption);
                }}
                placeholder="None"
              >
                {(organization.prospect_list ?? []).map(prospectList => (
                  <option value={prospectList.id}>{prospectList.name}</option>
                ))}
              </InputSelect>
            </InsetLabel>
          }
        />
      )}

      <GeneralConfirmationModal
        message={
          !!userLogged?.moadbCustomerId ? (
            <>
              Prospect will be licensed for 1 credit. You currently have{' '}
              {credits.toLocaleString()} credits.
              <strong>This action cannot be undone.</strong>
            </>
          ) : (
            <>
              Prospect will be marked as licensed for this customer.{' '}
              <strong>This action cannot be undone.</strong>
            </>
          )
        }
        onHide={() => setMarkSingleProspect(null)}
        onOK={onMarkSinglePurchased}
        show={!!markSingleProspect}
        title={!!userLogged?.moadbCustomerId ? 'License' : 'Mark As Licensed'}
        footerComponents={
          <InsetLabel
            label="Add Prospect to List"
            info="Select a list from the dropdown or enter the name of a new list prospect should be added to."
          >
            <InputSelect
              disabled={!orgHasBasicAccess(organization)}
              className="mb-3"
              value={
                prospectListValueIsOption
                  ? (organization.prospect_list ?? []).filter(
                      prospectList => prospectList.id === prospectListValue,
                    )?.[0]?.name
                  : prospectListValue
              }
              onChange={e => {
                console.log('debug', e.target);
                setProspectListValue(e.target.value);
                setProspectListValueIsOption(e.target.isOption);
              }}
              placeholder="Select List"
            >
              {(organization.prospect_list ?? []).map(prospectList => (
                <option value={prospectList.id}>{prospectList.name}</option>
              ))}
            </InputSelect>
          </InsetLabel>
        }
      />

      <GeneralConfirmationModal
        isError
        message={
          <div className="fs-5 text-danger">
            This export will include political columns. Be certain to exclude
            political columns when exporting for commercial clients. Sending
            commercial clients political columns may have{' '}
            <u>legal ramifications</u>.
          </div>
        }
        onHide={() => setShowDownloadModal(false)}
        onOK={onGenerateCSV}
        show={showDownloadModal}
        title="Generate CSV"
      />

      <GeneralConfirmationModal
        isError
        message={
          <div className={!includePolitical ? 'fs-5 text-danger' : ''}>
            {`Are you certain you want to ${
              includePolitical ? 'exclude' : 'include'
            } politcal columns?`}
            {!includePolitical && (
              <>
                &nbsp; Be certain to exclude political columns when exporting
                for commercial clients. Sending commercial clients political
                columns may have <u>legal ramifications</u>.
              </>
            )}
          </div>
        }
        onHide={() => setShowPoliticalModal(false)}
        onOK={() => {
          updateIncludePolitical(!includePolitical);
          setShowPoliticalModal(false);
        }}
        show={showPoliticalModal}
        title="Include Political Columns"
      />

      <GeneralModal
        message={slackConfirmationMessage}
        onHide={clearConfirmationState}
        show={!!slackConfirmationMessage}
        title={slackConfirmationTitle}
      />

      <DataProfileModal
        customerId={facets.current.Facets.customerId}
        onComplete={newFacets => {
          setShowDataProfileModal(false);
          facets.update(newFacets);
        }}
        onHide={() => setShowDataProfileModal(false)}
        show={showDataProfileModal}
      />

      <NewModal
        customerId={!!userLogged ? userLogged.moadbCustomerId : null}
        facetedSearches={facetedSearches}
        onHide={() => setShowNewModal(false)}
        onOpen={openFacetedSearch}
        show={showNewModal}
      />

      <OpenModal
        facetedSearches={facetedSearches}
        onHide={() => setShowOpenModal(false)}
        onOpen={openFacetedSearch}
        show={showOpenModel}
      />

      <SaveModal
        onHide={() => setShowSaveModal(false)}
        onSave={saveFacetedSearch}
        facets={{ ...facets.current, searchPurchased }}
        updateFacets={updateFacets}
        show={showSaveModal}
      />

      <UpdloadAndMarkAsPurchsedModal
        onHide={() => setShowUploadAndMarkAsPurchsedModal(false)}
        show={showUploadAndMarkAsPurchsedModal}
      />

      <SuggestEditModal
        show={!!suggestionTarget}
        onHide={() => setSuggestionTarget(null)}
        onSend={suggestion => {
          requestSendingSlackMessage(
            'suggestedEdit',
            [
              'Suggessted Edit.',
              suggestion,
              `Prospect ID: ${suggestionTarget.id}`,
              `Prospect Name: ${suggestionTarget.first_name} ${suggestionTarget.last_name}`,
            ].join('\r\n'),
            'Suggessted Edit.',
            'Suggessted edit sent',
          );
          setSuggestionTarget(null);
        }}
        suggestionTarget={suggestionTarget}
      />

      <CreditsModal
        credits={credits}
        delta={creditsDelta}
        onHide={() => setCreditsDelta(0)}
        show={creditsDelta > 0}
      />

      {!!aiLetterSubject && (
        <AIGenerationModal
          aiLetterSubject={aiLetterSubject}
          credits={credits}
          onHide={() => setAILetterSubject(null)}
          organization={organization}
          setOrganization={setOrganization}
          show={!!setAILetterSubject}
        />
      )}

      <LoadingOverlay show={resultsAreLoading} />

      <GuideSection>
        <Row>
          <Col md={2} className="border border-0 border-end position-relative">
            <div className="sticky-top">
              <Navbar expand="lg">
                <Navbar.Toggle />
                <Navbar.Collapse>
                  <Nav className="me-auto">
                    <Nav.Item>
                      <ButtonGroup>
                        <ToolTip title="New Search">
                          <Button
                            disabled={!orgHasBasicAccess(organization)}
                            variant="light"
                            onClick={() => setShowNewModal(true)}
                          >
                            <Square />
                          </Button>
                        </ToolTip>
                        <ToolTip title="Open Search">
                          <Button
                            disabled={!orgHasBasicAccess(organization)}
                            variant="light"
                            onClick={() => setShowOpenModal(true)}
                          >
                            <ArrowUpSquare />
                          </Button>
                        </ToolTip>
                        <ToolTip title="Save Search">
                          <Button
                            disabled={!orgHasBasicAccess(organization)}
                            variant="light"
                            onClick={() => saveQueryOrSaveQueryAs()}
                          >
                            <ArrowDownSquare />
                          </Button>
                        </ToolTip>
                        <ToolTip title="Save Search As">
                          <Button
                            disabled={!orgHasBasicAccess(organization)}
                            variant="light"
                            onClick={() => saveQueryAs()}
                          >
                            <ArrowDownSquareFill />
                          </Button>
                        </ToolTip>
                        <ToolTip title="Edit Search Info">
                          <Button
                            disabled={
                              !orgHasBasicAccess(organization) ||
                              !facets?.current?._id
                            }
                            variant="light"
                            onClick={() => setShowFileDetailsModal(true)}
                          >
                            <InfoSquare />
                          </Button>
                        </ToolTip>
                      </ButtonGroup>
                    </Nav.Item>

                    <Nav.Item>
                      <ButtonGroup>
                        <ToolTip title="Undo">
                          <Button
                            disabled={
                              !orgHasBasicAccess(organization) || facets.isFirst
                            }
                            variant="light"
                            onClick={() => facets.goBack()}
                          >
                            <ArrowCounterclockwise />
                          </Button>
                        </ToolTip>

                        <ToolTip title="Redo">
                          <Button
                            disabled={
                              !orgHasBasicAccess(organization) || facets.isLast
                            }
                            variant="light"
                            onClick={() => facets.goForward()}
                          >
                            <ArrowClockwise />
                          </Button>
                        </ToolTip>
                      </ButtonGroup>
                    </Nav.Item>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              {((userLogged?.permissions ?? []) as any).includes(
                'facetedSearchCanSelectCustomer',
              ) && (
                <InsetLabel label="Customer">
                  <MOADBDropdownCustomers
                    className="mb-3"
                    value={facets?.current?.Facets?.customerId}
                    onChange={e =>
                      facets.update({
                        ...facets.current,
                        Facets: {
                          ...facets?.current?.Facets,
                          customerId: e.target.value,
                        },
                      })
                    }
                  />
                </InsetLabel>
              )}

              <div className="fs-5 lh-sm text-center">
                Search over 200 million
                <br />
                A.I. Prediction Profiles
                <br />
                <small>(Limit 500 results per search)</small>
              </div>

              <Collapsable className="facet mb-3">
                <CollapsableItem>
                  <CollapsableHeader>Location + Proximity</CollapsableHeader>
                  <CollapsableBody>
                    <InsetLabel
                      label="Region"
                      info="Select one or more geographical regions to filter states within the next field."
                    >
                      <MOADBDropdownRegions
                        disabled={!orgHasBasicAccess(organization)}
                        className="mb-3"
                        value={facets?.current?.Facets?.region}
                        onChange={e => {
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              county: [],
                              state: states
                                .filter(state =>
                                  e.target.value.includes(state.region),
                                )
                                .map(state => state.state_abbr),
                              region: e.target.value,
                            },
                          });
                        }}
                      />
                    </InsetLabel>

                    <Row>
                      <Col
                        xs={
                          facets?.current?.Facets?.state?.length === 1 ? 6 : 12
                        }
                      >
                        <InsetLabel
                          label="State"
                          info="Select one or more states. Matched prospects will have a listed address within the provided state(s)."
                        >
                          <MOADBDropdownStates
                            disabled={!orgHasBasicAccess(organization)}
                            className="mb-3"
                            value={facets?.current?.Facets?.state}
                            region={facets?.current?.Facets?.region}
                            onChange={e =>
                              facets.update({
                                Facets: {
                                  county: [],
                                  state: e.target.value,
                                },
                              })
                            }
                          />
                        </InsetLabel>
                      </Col>

                      {facets?.current?.Facets?.state?.length === 1 && (
                        <Col xs={6}>
                          <InsetLabel
                            label="County"
                            info={`Select one or more ${facets?.current?.Facets?.state} counties. Matched prospects will have a listed address within one of the provided counties.`}
                          >
                            <MOADBDropdownCounties
                              disabled={!orgHasBasicAccess(organization)}
                              className="mb-3"
                              state={facets?.current?.Facets?.state}
                              value={facets?.current?.Facets?.county}
                              onChange={e =>
                                facets.update({
                                  Facets: {
                                    county: e.target.value,
                                  },
                                })
                              }
                            />
                          </InsetLabel>
                        </Col>
                      )}
                    </Row>

                    <AddressLookup
                      disabled={!orgHasBasicAccess(organization)}
                      className="mb-3"
                      value={facets?.current?.Facets?.address}
                      valueDistance={facets?.current?.Facets?.distance}
                      onChange={e => {
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            address: e.target.value.address,
                            distance: parseInt(e.target.value.distance, 10),
                            latLon: !!e.target.value?.latLon
                              ? {
                                  lat: toFixed(e.target.value?.latLon?.lat, 3),
                                  lon: toFixed(e.target.value?.latLon?.lon, 3),
                                }
                              : null,
                          },
                        });
                      }}
                    />
                  </CollapsableBody>
                </CollapsableItem>

                <CollapsableItem>
                  <CollapsableHeader>Interests + Values</CollapsableHeader>
                  <CollapsableBody>
                    <InsetLabel
                      label="Interests"
                      info="Select one or more interests. Matched prospects will have, at least, one of selected interests."
                    >
                      <MultiSelect
                        disabled={!orgHasBasicAccess(organization)}
                        className="mb-3"
                        value={facets?.current?.Facets?.interests}
                        onChange={e =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              interests: e.target.value,
                            },
                          })
                        }
                        placeholder="Select Interests"
                      >
                        <option value="DONATES_TO_ANIMAL_WELFARE">
                          Animal Welfare{' '}
                        </option>
                        <option value="DONATES_TO_ARTS_AND_CULTURE">
                          Arts And Culture
                        </option>
                        <option value="DONATES_TO_CHILDRENS_CAUSES">
                          Childrens Causes
                        </option>
                        <option value="DONATES_TO_CONSERVATIVE_CAUSES">
                          Conservative Causes
                        </option>
                        <option value="DONATES_TO_HEALTHCARE">
                          Healthcare
                        </option>
                        <option value="DONATES_TO_INTERNATIONAL_AID_CAUSES">
                          International Aid Causes
                        </option>
                        <option value="DONATES_TO_LIBERAL_CAUSES">
                          Liberal Causes
                        </option>
                        <option value="DONATES_TO_LOCAL_COMMUNITY">
                          Local Community
                        </option>
                        <option value="DONATES_TO_POLITICAL_CAUSES">
                          Political Causes
                        </option>
                        <option value="DONATES_TO_VETERANS_CAUSES">
                          Veteran Causes
                        </option>
                        <option value="DONATES_TO_WILDLIFE_PRESERVATION">
                          Wildlife Preservation
                        </option>
                      </MultiSelect>
                    </InsetLabel>

                    <InsetLabel
                      label="Politics"
                      info="Select one or more political views. Matched prospects will hold one of the selected political views."
                    >
                      <MultiSelect
                        disabled={!orgHasBasicAccess(organization)}
                        className="mb-3"
                        value={facets?.current?.Facets?.ideology}
                        onChange={e =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              ideology: e.target.value,
                            },
                          })
                        }
                        placeholder="Select Politics"
                      >
                        <option value="1">Conservative</option>
                        <option value="2">Conservative Leaning</option>
                        <option value="3">Unaffiliated / Moderate</option>
                        <option value="4">Liberal Leaning</option>
                        <option value="5">Liberal</option>
                      </MultiSelect>
                    </InsetLabel>

                    <InsetLabel
                      label="Religion"
                      info="Select one or more religions. Matched prospects will hold one of the selected religions."
                    >
                      <MultiSelect
                        disabled={!orgHasBasicAccess(organization)}
                        className="mb-3"
                        value={facets?.current?.Facets?.religion}
                        onChange={e =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              religion: e.target.value,
                            },
                          })
                        }
                        placeholder="Select Religion"
                      >
                        <option value="Buddhist">Buddhist</option>
                        <option value="Catholic">Catholic</option>
                        <option value="Eastern Orthodox">
                          Eastern Orthodox
                        </option>
                        <option value="Hindu">Hindu</option>
                        <option value="Jewish">Jewish</option>
                        <option value="Lutheran">Lutheran</option>
                        <option value="Mormon">Mormon</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Oriental Orthodox">
                          Oriental Orthodox
                        </option>
                        <option value="Protestant">Protestant</option>
                        <option value="Shint">Shint</option>
                        <option value="Sikh">Sikh</option>
                        <option value="Unknown">Unknown</option>
                      </MultiSelect>
                    </InsetLabel>
                  </CollapsableBody>
                </CollapsableItem>

                <CollapsableItem>
                  <CollapsableHeader>Wealth + Giving</CollapsableHeader>
                  <CollapsableBody>
                    <InsetLabel
                      label="Home Value"
                      info="Enter a minimum home value. Matched prospects will have an estimated home value equal to or greater than the value entered."
                    >
                      <InputSelect
                        number
                        disabled={!orgHasBasicAccess(organization)}
                        className="mb-3"
                        value={facets?.current?.Facets?.minEstimatedHomeValue}
                        onChange={e =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              minEstimatedHomeValue: e.target.value,
                            },
                          })
                        }
                        placeholder="Select Min Home Value"
                      >
                        <option value={0}>0</option>
                        <option value={100000}>100,000</option>
                        <option value={250000}>250,000</option>
                        <option value={500000}>500,000</option>
                        <option value={1000000}>1,000,000</option>
                        <option value={1500000}>1,500,000</option>
                        <option value={2000000}>2,000,000</option>
                        <option value={2500000}>2,500,000</option>
                        <option value={3000000}>3,000,000</option>
                        <option value={3500000}>3,500,000</option>
                        <option value={4000000}>4,000,000</option>
                        <option value={4500000}>4,500,000</option>
                        <option value={5000000}>5,000,000</option>
                        <option value={5500000}>5,500,000</option>
                      </InputSelect>
                    </InsetLabel>

                    <InsetLabel
                      label="Household Income"
                      info="Enter a minimum household income. Matched prospects will have an estimated household income equal to or greater than the value entered."
                    >
                      <InputSelect
                        number
                        disabled={!orgHasBasicAccess(organization)}
                        className="mb-3"
                        value={facets?.current?.Facets?.minEstimatedIncome}
                        onChange={e =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              minEstimatedIncome: e.target.value,
                            },
                          })
                        }
                        placeholder="Select Min Household Income"
                      >
                        <option value={0}>0</option>
                        <option value={50000}>50,000</option>
                        <option value={100000}>100,000</option>
                        <option value={250000}>250,000</option>
                        <option value={500000}>500,000</option>
                        <option value={1000000}>1,000,000</option>
                      </InputSelect>
                    </InsetLabel>

                    <InsetLabel
                      label="Donation Capacity"
                      info="Enter a minimum donation capacity. Matched prospects will have an estimated donation capacity equal to or greater than the value entered."
                    >
                      <InputSelect
                        number
                        disabled={!orgHasBasicAccess(organization)}
                        className="mb-3"
                        value={facets?.current?.Facets?.minDonationCapacity}
                        onChange={e =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              minDonationCapacity: e.target.value,
                            },
                          })
                        }
                        placeholder="Select Min Donation Capacity"
                      >
                        <option value={0}>0</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={1000}>1000</option>
                        <option value={5000}>5000</option>
                        <option value={10000}>10000</option>
                      </InputSelect>
                    </InsetLabel>
                  </CollapsableBody>
                </CollapsableItem>

                <CollapsableItem>
                  <CollapsableHeader>Individual Look-up</CollapsableHeader>
                  <CollapsableBody>
                    <InsetLabel
                      label="Person's Name"
                      info="Enter a prospect's first name, last name, or both. Matched prospects will have the provided name."
                    >
                      <Form.Control
                        disabled={!orgHasBasicAccess(organization)}
                        className="mb-3"
                        value={facets?.current?.Facets?.name}
                        onChange={e =>
                          facets.update({
                            ...facets.current,
                            Facets: {
                              ...facets?.current?.Facets,
                              name: e.target.value,
                            },
                          })
                        }
                      />
                    </InsetLabel>
                  </CollapsableBody>
                </CollapsableItem>

                <CollapsableItem>
                  <CollapsableHeader>Demographics</CollapsableHeader>
                  <CollapsableBody>
                    <InputGroup>
                      <InsetLabel
                        label="Minumum Age"
                        info="Enter a prospect's minumum age. Matched prospects will be that age or younger. Set to 0 (zero) for no minimum age."
                      >
                        <Form.Control
                          disabled={!orgHasBasicAccess(organization)}
                          type="number"
                          className="mb-3"
                          value={facets?.current?.Facets?.minAge}
                          onChange={e =>
                            facets.update({
                              ...facets.current,
                              Facets: {
                                ...facets?.current?.Facets,
                                minAge: e.target.value,
                              },
                            })
                          }
                        />
                      </InsetLabel>

                      <InsetLabel
                        label="Maximum Age"
                        info="Enter a prospect's maximum age. Matched prospects will be that age or older. Set to 0 (zero) for no maximum age."
                      >
                        <Form.Control
                          disabled={!orgHasBasicAccess(organization)}
                          type="number"
                          className="mb-3"
                          value={facets?.current?.Facets?.maxAge}
                          onChange={e =>
                            facets.update({
                              ...facets.current,
                              Facets: {
                                ...facets?.current?.Facets,
                                maxAge: e.target.value,
                              },
                            })
                          }
                        />
                      </InsetLabel>
                    </InputGroup>

                    {((userLogged?.permissions ?? []) as any).includes(
                      'facetedSearchCanFilterEmailPhone4YrGrad',
                    ) && (
                      <ButtonGroup className="mb-3 w-100" size="sm">
                        <Toggle
                          disabled={!orgHasBasicAccess(organization)}
                          singleButton
                          labelOff={
                            <>
                              <Square /> Email
                            </>
                          }
                          labelOn={
                            <>
                              <CheckSquare /> Email
                            </>
                          }
                          value={facets?.current?.Facets?.hasEmail}
                          onChange={value =>
                            facets.update({
                              ...facets.current,
                              Facets: {
                                ...facets?.current?.Facets,
                                hasEmail: value,
                              },
                            })
                          }
                        />

                        <Toggle
                          disabled={!orgHasBasicAccess(organization)}
                          singleButton
                          labelOff={
                            <>
                              <Square /> Phone
                            </>
                          }
                          labelOn={
                            <>
                              <CheckSquare /> Phone
                            </>
                          }
                          value={facets?.current?.Facets?.hasPhone}
                          onChange={value =>
                            facets.update({
                              ...facets.current,
                              Facets: {
                                ...facets?.current?.Facets,
                                hasPhone: value,
                              },
                            })
                          }
                        />

                        <Toggle
                          disabled={!orgHasBasicAccess(organization)}
                          singleButton
                          labelOff={
                            <>
                              <Square /> 4 Yr Grad
                            </>
                          }
                          labelOn={
                            <>
                              <CheckSquare /> 4 Yr Grad
                            </>
                          }
                          value={facets?.current?.Facets?.is4YrGrad}
                          onChange={value =>
                            facets.update({
                              ...facets.current,
                              Facets: {
                                ...facets?.current?.Facets,
                                is4YrGrad: value,
                              },
                            })
                          }
                        />
                      </ButtonGroup>
                    )}

                    {/*<InsetLabel label="Sort">
                    <MOADBDropdownColumns
                      className="mb-3"
                      value={facets?.current?.Facets?.sort}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            sort: e.target.value,
                          },
                        })
                      }
                    ></MOADBDropdownColumns>
                  </InsetLabel>

                  <InsetLabel label="Order">
                    <Select
                      className="mb-3"
                      value={facets?.current?.Facets?.order}
                      onChange={e =>
                        facets.update({
                          ...facets.current,
                          Facets: {
                            ...facets?.current?.Facets,
                            order: e.target.value,
                          },
                        })
                      }
                    >
                      <option value="asc">Asc</option>
                      <option value="desc">Desc</option>
                    </Select>
                  </InsetLabel>*/}
                  </CollapsableBody>
                </CollapsableItem>
              </Collapsable>

              {((userLogged?.permissions ?? []) as any).includes(
                'facetedSearchCanDownloadCSV',
              ) && (
                <InsetLabel
                  label="Limit"
                  info="Enter the maximum records returned. Value must be less than or equal to 5,000."
                >
                  <Form.Control
                    disabled={!orgHasBasicAccess(organization)}
                    type="number"
                    max={1000}
                    value={facets?.current?.Facets?.limit}
                    onChange={e =>
                      facets.update({
                        ...facets.current,
                        Facets: {
                          ...facets?.current?.Facets,
                          limit: Math.min(5000, parseInt(e.target.value, 10)),
                        },
                      })
                    }
                  />
                </InsetLabel>
              )}

              {!!facetError && (
                <Alert variant="danger" className="mt-3">
                  {facetError}
                </Alert>
              )}

              {(credits > 0 && orgHasBasicAccess(organization)) ||
              organization.canBypassPaywall ||
              searchPurchased ? (
                <ButtonGroup className="mt-3 w-100" size="sm">
                  {/*<ToolTip title="Create Data Profile">
                  <Button
                    variant={!!results ? 'outline-light' : 'dark'}
                    onClick={() => setShowDataProfileModal(true)}
                  >
                    <PersonLinesFill /> Data Profile
                  </Button>
                </ToolTip>*/}

                  <ToolTip title="Return Total">
                    <Button
                      disabled={!orgHasBasicAccess(organization)}
                      variant="outline-primary"
                      onClick={() => onSearch(false, false, null, true)}
                    >
                      Count
                    </Button>
                  </ToolTip>

                  <ToolTip
                    title={
                      searchPurchased
                        ? 'Search Only Licensed Records (0 Credits)'
                        : 'Search (1 Credit)'
                    }
                  >
                    <Button
                      disabled={!orgHasBasicAccess(organization)}
                      onClick={() => onSearch()}
                    >
                      Search
                    </Button>
                  </ToolTip>
                </ButtonGroup>
              ) : (
                <ToolTip
                  title={
                    !orgHasBasicAccess(organization)
                      ? 'Click to Upgrade'
                      : 'Click to Purchase More Credits'
                  }
                >
                  <Button className="mt-3 w-100" size="sm" href="/settings">
                    {!orgHasBasicAccess(organization)
                      ? 'Upgrade to Continue'
                      : 'Purchase Credits to Continue'}
                  </Button>
                </ToolTip>
              )}

              <ButtonGroup className="w-100">
                <ToolTip title="Clear Search Criteria">
                  <Button
                    className="ps-0 text-decoration-none"
                    size="sm"
                    variant="link"
                    onClick={() =>
                      facets.update(
                        newFacets({
                          customerId: userLogged?.moadbCustomerId,
                        }),
                      )
                    }
                  >
                    Clear Search Criteria
                  </Button>
                </ToolTip>
              </ButtonGroup>
            </div>
          </Col>

          <Col
            md={10}
            className="p-0 my-3 my-md-0"
            style={{ minHeight: '75vh' }}
          >
            {!!markedAsPurchased && (
              <Alert variant="info">
                Prior results have been marked as licensed. Please execute
                another faceted search to explore what records are still
                available.
              </Alert>
            )}

            {!!error && <Alert variant="danger">{error}</Alert>}

            {!results && (
              <div
                className="fs-3 h-100 w-100 px-2 py-2"
                style={{
                  backgroundImage: `url(${
                    !!orgHasBasicAccess(organization) ? PreviewLive : Preview
                  })`,
                  backgroundSize: 'cover',
                }}
              >
                {!!orgHasBasicAccess(organization) && (
                  <Card className="lh-sm mx-auto text-center w-50">
                    <Card.Body>
                      <div className="fw-bold">Prospect Search</div>
                      Search over 200 million Profiles by name, geography,
                      wealth or psychographics
                    </Card.Body>
                  </Card>
                )}

                {!orgHasBasicAccess(organization) && (
                  <div className="text-center">
                    <Button className="lh-sm fs-3 w-50" href="/settings">
                      Unlock Search Feature
                      <br />
                      (Professional Plan Required)
                    </Button>
                  </div>
                )}
              </div>
            )}

            {!!results && !showMap && (
              <ListDashboard
                handleDownloadCSV={handleDownloadCSV}
                includePolitical={includePolitical}
                organization={organization}
                prospects={results}
                revealedColumns={revealedColumns}
                setOrganization={setOrganization}
                setRevealedColumns={setRevealedColumns}
                showContactInfo={showContactInfo}
                userLogged={userLogged}
              />
            )}

            {!!results && showMap && (
              <div className="mapContainer">
                {!counted && !searchPurchased && (
                  <div className="licenseAllBox p-2">
                    <small>Add All Search Results to My Prospects</small>

                    <ToolTip title="Mark records as licensed">
                      <Button
                        className="mt-2 w-100"
                        size="sm"
                        onClick={() => {
                          if (
                            credits < purgeDTIDs(organization, results).length
                          )
                            setCreditsDelta(
                              purgeDTIDs(organization, results).length -
                                credits,
                            );
                          else setShowMarkModal(true);
                        }}
                      >
                        {!!userLogged?.moadbCustomerId
                          ? 'License All'
                          : 'Mark As Licensed'}
                      </Button>
                    </ToolTip>
                  </div>
                )}

                {!counted && searchPurchased && (
                  <div className="licenseAllBox p-2">
                    <ButtonGroup>
                      {!!results && (
                        <>
                          {!counted &&
                            ((userLogged?.permissions ?? []) as any).includes(
                              'facetedSearchCanViewPolitical',
                            ) && (
                              <ToolTip
                                title={`${
                                  includePolitical ? 'Exclude' : 'Include'
                                } Poltical Columns`}
                              >
                                <Button
                                  size="sm"
                                  variant="dark"
                                  onClick={() => setShowPoliticalModal(true)}
                                >
                                  {includePolitical ? (
                                    <CheckSquare />
                                  ) : (
                                    <Square />
                                  )}{' '}
                                  Political
                                </Button>
                              </ToolTip>
                            )}

                          {!counted &&
                            (searchPurchased ||
                              ((userLogged?.permissions ?? []) as any).includes(
                                'facetedSearchCanDownloadCSV',
                              )) && (
                              <ToolTip title="Generate CSV">
                                <Button size="sm" onClick={handleDownloadCSV}>
                                  Download CSV
                                </Button>
                              </ToolTip>
                            )}
                        </>
                      )}

                      {!searchPurchased &&
                        ((userLogged?.permissions ?? []) as any).includes(
                          'facetedSearchCanUploadAndMarkAsPurchased',
                        ) && (
                          <ToolTip title="Upload & Mark As Purchsed">
                            <Button
                              size="sm"
                              variant="light"
                              onClick={() =>
                                setShowUploadAndMarkAsPurchsedModal(true)
                              }
                            >
                              <Upload />
                              &nbsp; Upload & Mark As Licensed
                            </Button>
                          </ToolTip>
                        )}

                      {/*<ToolTip title="Switch to Query Search">
                    <Button
                      variant={!!results ? 'outline-light' : 'dark'}
                      onClick={() => setUseQuery(true)}
                    >
                      <ArrowLeftRight />
                    </Button>
                  </ToolTip>*/}
                    </ButtonGroup>
                  </div>
                )}

                <MOADBMap
                  credits={credits}
                  key={JSON.stringify(results)}
                  data={results}
                  onColumnFilter={onColumnFilter}
                  onColumnHeader={onColumnHeader}
                  purchasedIcon={purchasedIcon}
                  searchPurchased={searchPurchased}
                  setCreditsDelta={setCreditsDelta}
                  setMarkSingleProspect={setMarkSingleProspect}
                  showContactInfo={showContactInfo}
                  setAILetterSubject={setAILetterSubject}
                  setSuggestionTarget={setSuggestionTarget}
                />
              </div>
            )}
          </Col>
        </Row>
      </GuideSection>
    </>
  );
};
