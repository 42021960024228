import * as React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { InfoCircleFill } from 'react-bootstrap-icons';

const WhiteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip disableInteractive {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#1f2d54',
    boxShadow: `0px 0px 12px 5px #e6e9f1`,
    textAlign: 'center',
    fontSize: '13px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    maxWidth: '230px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#fff',
  },
}));

export default function InfoToolTip(props) {
  return (
    <WhiteTooltip {...props}>
      <InfoCircleFill />
    </WhiteTooltip>
  );
}
