import { updateOrganization } from 'api/organization.service';
import React, { useState } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  FloatingLabel,
  Form,
  Modal,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

const EditListModal = ({
  list,
  organization,
  onHide,
  setOrganization,
  show,
  userLogged,
}) => {
  const [error, setError] = useState<any>(null);
  const [description, setDescription] = useState<any>(list?.description);
  const [name, setName] = useState<any>(list?.name);

  const handleEditList = async () => {
    if (!name) setError('Please, provide a name');
    else if (!description) setError('Please, provide a description');
    else {
      setError(null);

      const updatedProspectList = [
        ...organization.prospect_list.filter(
          prospectList => prospectList.id !== list.id,
        ),
        {
          ...list,
          description,
          name,
          updatedAt: new Date().toISOString(),
        },
      ];

      await updateOrganization(
        { prospect_list: updatedProspectList },
        { Organization_ID: userLogged?.organizationId },
      );

      setOrganization({ ...organization, prospect_list: updatedProspectList });
      setDescription(null);
      setName(null);
      toast.success('List updated successfully');
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FloatingLabel label="Name">
          <Form.Control
            className="mb-3"
            value={name ?? ''}
            onChange={e => setName(e.target.value)}
          />
        </FloatingLabel>
        <FloatingLabel label="Description">
          <Form.Control
            value={description ?? ''}
            onChange={e => setDescription(e.target.value)}
          />
        </FloatingLabel>
        {!!error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditList}>
            Edit
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default EditListModal;
