const fieldMapOptions = {
  /*Address: {
    label: 'Address 1',
    isRequired: false,
    alias: ['address'],
  },
  CellPhone_AreaCode: {
    label: 'Cell Phone Area Code',
    isNumber: true,
    isRequired: false,
    alias: ['cell area', 'mobile area'],
  },
  CellPhone_Number: {
    label: 'Cell Phone',
    isNumber: true,
    isRequired: false,
    alias: ['cell', 'mobile'],
  },*/
  CITY: {
    label: 'City',
    isRequired: false,
    alias: ['city'],
  },
  /*Email: {
    label: 'Email',
    isRequired: false,
    alias: ['email'],
  },*/
  'fn.first_name': {
    label: 'First Name',
    isRequired: false,
    alias: ['first', 'first name'],
  },
  /*LandLine_AreaCode: {
    label: 'Phone Area Code',
    isNumber: true,
    isRequired: false,
    alias: ['area code', 'phone area'],
  },
  LandLine_Number: {
    label: 'Phone Number',
    isNumber: true,
    isRequired: false,
    alias: ['number', 'phone'],
  },*/
  'ln.last_name': {
    label: 'Last Name',
    isRequired: false,
    alias: ['last'],
  },
  STATE: {
    label: 'State',
    isRequired: false,
    alias: ['state'],
  },
  POSTAL_CODE: {
    label: 'Zip',
    isNumber: true,
    isRequired: false,
    alias: ['postal', 'zip'],
  },
};

export default fieldMapOptions;
