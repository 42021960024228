import React from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Modal,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BiError, BiInfoCircle } from 'react-icons/bi';

const GeneralConfirmationModal = ({
  cancelLabel = 'Cancel',
  closeButton = true,
  footerComponents = <> </>,
  isError,
  message,
  okLabel = 'OK',
  onCancel,
  onHide,
  onOK,
  show,
  title,
}) => {
  return (
    show && (
      <Modal show onHide={onHide}>
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>
            {isError ? <BiError /> : <BiInfoCircle />} &nbsp;
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Container fluid className="p-0">
            <Row>
              <Col xs={8}>{footerComponents}</Col>
              <Col xs={4}>
                <ButtonGroup>
                  <Button variant="light" onClick={onCancel ?? onHide}>
                    {cancelLabel}
                  </Button>
                  <Button onClick={onOK}>{okLabel}</Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    )
  );
};

GeneralConfirmationModal.propTypes = {
  cancelLabel: PropTypes.string,
  closeButton: PropTypes.bool,
  isError: PropTypes.bool,
  message: PropTypes.any,
  okLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onHide: PropTypes.func,
  onOK: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
};

export default GeneralConfirmationModal;
