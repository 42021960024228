import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import StepSequence from './StepSequence';
import StepIMAP from './StepIMAP';
import AIGenerationForm from '../AIGenerationForm/indext';
import StepIndicator from '../StepIndicator';

const SequenceModal = ({ onHide, organization, show }) => {
  const [continueCaption, setContinueCaption] = useState('Continue');
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [imap, setImap] = useState<any>(null);
  const [sequence, setSequence] = useState<any>(null);
  const [step, setStep] = useState(1);

  const updateStatus = useCallback(
    newStep => {
      setStep(newStep);
      setContinueCaption(newStep < 3 ? 'Continue' : 'Coming Soon');

      if (newStep === 1 && !sequence) setContinueDisabled(true);
      else if (newStep === 2 && !imap) setContinueDisabled(true);
      else if (newStep === 3) setContinueDisabled(true);
      else setContinueDisabled(false);
    },
    [imap, sequence],
  );

  const onBack = () => updateStatus(step - 1);

  const onContinue = () => updateStatus(step + 1);

  useEffect(() => updateStatus(step), [step, updateStatus]);

  return (
    <Modal size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Outreach Sequence</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StepIndicator
          className="mb-3"
          labels={['Sequence', 'Email', 'AI']}
          step={step}
        />

        {step === 1 && (
          <StepSequence sequence={sequence} setSequence={setSequence} />
        )}
        {step === 2 && <StepIMAP imap={imap} setImap={setImap} />}
        {step === 3 && (
          <AIGenerationForm
            aiLetterSubject={null}
            organization={organization}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="outline-dark" onClick={onHide}>
            Cancel
          </Button>
          {step > 1 && (
            <Button variant="outline-dark" onClick={onBack}>
              Back
            </Button>
          )}
          <Button
            variant="primary"
            disabled={continueDisabled}
            onClick={onContinue}
          >
            {continueCaption}
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default SequenceModal;
