import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';
import { UserSession } from './types';

export interface AuthenticationSliceState {
  isAuthenticated: boolean;
  user?: UserSession;
  isAuthenticationError: boolean;
  isLogingIn: boolean;
  errorMessage: string;
}

export const initialState: AuthenticationSliceState = {
  isAuthenticated: false,
  isAuthenticationError: false,
  isLogingIn: false,
  errorMessage: '',
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    // action: function
    startLoginProcess: state => {
      state.isLogingIn = true;
    },
    finishLoginProcess: state => {
      state.isLogingIn = false;
    },
    usernamePasswordLogin: (
      state,
      action: PayloadAction<UserSession, string, never>,
    ) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      sessionStorage.setItem('Session-Token', action?.payload?.sessionToken);
      sessionStorage.setItem(
        'expiration',
        action?.payload?.expiration.toString(),
      );
    },
    googleLogin: (state, action: PayloadAction<UserSession, string, never>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      sessionStorage.setItem('Session-Token', action?.payload?.sessionToken);
      sessionStorage.setItem(
        'expiration',
        action?.payload?.expiration.toString(),
      );
    },
    loginError: (state, action: PayloadAction<string, string, never>) => {
      state.errorMessage = action.payload;
      state.isAuthenticationError = true;
      state.isAuthenticated = false;
    },
    makeLogout: state => {
      state.isAuthenticated = initialState.isAuthenticated;
      state.isLogingIn = initialState.isLogingIn;
      state.errorMessage = initialState.errorMessage;
      state.user = initialState.user;
      state.isAuthenticationError = initialState.isAuthenticationError;
      sessionStorage.clear();
    },
    acceptedToS: state => {
      if (state.user) state.user.acceptedToS = true;
    },
  },
});

export const useAuthSliceSlice = () => {
  useInjectReducer({
    key: authenticationSlice.name,
    reducer: authenticationSlice.reducer,
  });
  return { actions: authenticationSlice.actions };
};

export default authenticationSlice.reducer;
