import constants from 'app/config/constants';
import axiosInstance from 'utils/api';
import { IOrganization, PaginationResult } from './interfaces';
import { PipelineStage } from 'mongoose';

export const addOrganizationProspectList = async (data, query) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/organizations/addList`;
  return await axiosInstance()
    .post(url, { data, query })
    .then(response => response.data);
};

export const getOrganizations = async () => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/organizations`;
  return await axiosInstance()
    .get<IOrganization[]>(url)
    .then(response => response.data);
};

export const aggregateOrganizations = async (
  aggregateQuery: PipelineStage[] = [],
) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/organizations/aggregate`;
  return await axiosInstance()
    .post<PaginationResult<IOrganization>[]>(url, {
      aggregateQuery,
    })
    .then(response => response.data);
};

export const createOrganization = async (data: Omit<IOrganization, '_id'>) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/organizations`;
  return await axiosInstance()
    .post<Partial<IOrganization>>(url, data)
    .then(response => response.data);
};

export const updateOrganization = async (
  data: Partial<IOrganization>,
  query,
) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/organizations`;
  return await axiosInstance()
    .put<Partial<IOrganization>>(url, { data, query })
    .then(response => response.data);
};

export const removeOrganization = async (id: string) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/organizations/${id}`;
  return await axiosInstance()
    .delete<IOrganization>(url)
    .then(response => response.data);
};

export const getOneOrganization = async query => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/organizations/getOne`;
  return await axiosInstance()
    .post<IOrganization>(url, { query })
    .then(response => response.data);
};

export const updateOrganizationProspectList = async (data, query) => {
  const endpoint = constants.BASE_ENDPOINT();
  const url = `${endpoint}/api/dre/collections/organizations/updateList`;
  return await axiosInstance()
    .post(url, { data, query })
    .then(response => response.data);
};
