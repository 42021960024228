import React from 'react';
import { Button, Nav } from 'react-bootstrap';

export const LeftNavigationButton = ({
  children,
  isActive = false,
  isMobile = false,
  onClick = () => {},
}) => {
  return isMobile ? (
    <Nav.Link
      className={isActive ? 'bg-primary text-white' : ''}
      onClick={onClick}
    >
      {children}
    </Nav.Link>
  ) : (
    <Button
      variant={isActive ? 'primary' : 'light'}
      className={`leftNavigationButton fw-normal mb-1 ${
        isActive ? 'text-white' : ''
      } w-100`}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default LeftNavigationButton;
